import { action, observable, runInAction, computed } from 'mobx'
import { toast } from 'react-toastify'
import { RefariDTOTypes } from '@refari-frontend/types'
import moment from 'moment'

import { ApiRoutes } from 'src/utils/Urls'
import API from 'src/utils/API'
import alertMessages from 'src/constants/alertMessages'
import { APIResponse } from 'src/types/APITypes'
import { type IJobAdsDetailModel } from 'src/models/JobAds/DetailsModel'
import Logger from 'src/utils/Logger'
import rootStore from './RootStore'
import standAloneStore from './StandAloneStore'
import MediaTagsModel, {
  IMediaTagsModel
} from 'src/models/StandAlone/MediaTags'
import QuestionSetsModel, {
  IQuestionSetsModel
} from 'src/models/StandAlone/QuestionSets'
import axios, { AxiosPromise } from 'axios'
import {
  generateErrorMessageFromFieldErrors,
  wrapIframesWithDiv
} from 'src/utils/helpers'

type PartnerActionExternalJobAdDetails = {
  externalJobAdId: number
}

type JobAdApiSpec =
  RefariDTOTypes['/dashboard/job-ads/{id}/']['get']['responses']['200']['content']['application/json']

export type AddressStatus = 'complete' | 'partialComplete' | 'none'

type JobAdMode = 'edit' | 'repost'

type OpenEditModalArgs = {
  jobAdMode: JobAdMode
}

type RepostJobAdvertAdditionalArgs = {
  cb?: () => void
}

export type IJobAdStore = {
  jobId: number
  jobsAdsListStore?: Record<string, unknown>
  jobAds: IJobAdsDetailModel | null
  isFetchingRequiredLists: boolean
  displayInternalRewardFee: boolean
  partnerActionExternalJobAdDetails: PartnerActionExternalJobAdDetails | null
  isOpenJobMediaTagSelectionModal: boolean
  isOpenJobQuestionSetSelectionModal: boolean
  isFetchingMediaTags: boolean
  mediaTags: Array<IMediaTagsModel>
  isFetchingQuestionSets: boolean
  questionSets: Array<IQuestionSetsModel>
  isOpenReplaceDetailedLocationModalFromATS: boolean
  currentLocationFieldIdForDetailedLocation: string
  isAddressFromATSEdited: boolean
  isATSAddressEmpty: boolean
  isOpenDetailedLocationInputModal: boolean
  workAddressStatus: AddressStatus
  isMediaTagRemoved: boolean
  isATSLocationClearedByUser: boolean
  jobAdMode?: JobAdMode
  isOpenEditModal: boolean
  isOpenJobAdRepostConfirmationModal: boolean
  setJobId: (jobId: number) => void
  fetchRequiredLists: () => Promise<void>
  // TODO:: fix form type
  updateJobDetails: (form: any) => Promise<void>
  updateQuestionSets: (questionSets: Array<IQuestionSetsModel>) => void
  updateMediaTags: (mediaTags: Array<IMediaTagsModel>) => void
  setJobsAdsListStore: (jobsAdsStore: any) => void
  setPartnerActionExternalJobAdDetails: (
    details: PartnerActionExternalJobAdDetails
  ) => void
  resetPartnerActionExternalJobAdDetails: () => void
  openJobMediaTagSelectionModal: () => void
  closeJobMediaTagSelectionModal: () => void
  startFetchingMediaTags: () => void
  stopFetchingMediaTags: () => void
  fetchMediaTags: (searchTag?: string) => Promise<void>
  openJobQuestionSetSelectionModal: () => void
  closeJobQuestionSetSelectionModal: () => void
  startFetchingQestionSets: () => void
  stopFetchingQestionSets: () => void
  fetchQestionSets: (searchTag?: string) => Promise<void>
  expireJobAdvert: (jobAdvertID: string) => Promise<void>
  setJobAds: (jobAds: IJobAdsDetailModel) => void
  updateStandAloneStoreFields: (jobAds: IJobAdsDetailModel | null) => void
  openReplaceDetailedLocationModalFromATS: (locationFieldId: string) => void
  closeReplaceDetailedLocationModalFromATS: () => void
  openDetailedLocationInputModal: (locationFieldId: string) => void
  closeDetailedLocationInputModal: () => void
  resetCurrentLocationFieldIdForDetailedLocation: () => void
  setIsMediaTagRemoved: (isMediaTagRemoved: boolean) => void
  setIsATSLocationClearedByUser: (isATSLocationClearedByUser: boolean) => void
  openEditModal: (openEditModalArgs?: OpenEditModalArgs) => void
  closeEditModal: () => void
  repostJobAdvert: (
    form: any,
    args: RepostJobAdvertAdditionalArgs
  ) => Promise<void>
  openJobAdRepostConfirmationModal: () => void
  closeJobAdRepostConfirmationModal: () => void
  validateReferralRewardFee: (
    value: number,
    currency: string,
    isInternal?: boolean
  ) => Promise<boolean | string>
}

class JobAdStore implements IJobAdStore {
  @observable jobId: IJobAdStore['jobId'] = 0
  @observable jobAds: IJobAdStore['jobAds'] = null
  @observable isFetchingRequiredLists: IJobAdStore['isFetchingRequiredLists'] =
    false
  @observable
  partnerActionExternalJobAdDetails: IJobAdStore['partnerActionExternalJobAdDetails'] =
    null
  @observable
  isOpenJobMediaTagSelectionModal = false
  @observable isFetchingMediaTags = false
  @observable mediaTags: IJobAdStore['mediaTags'] = []
  @observable
  isOpenJobQuestionSetSelectionModal = false
  @observable isFetchingQuestionSets = false
  @observable isOpenReplaceDetailedLocationModalFromATS = false
  @observable currentLocationFieldIdForDetailedLocation = ''
  @observable isOpenDetailedLocationInputModal = false
  @observable isMediaTagRemoved = false
  @observable isATSLocationClearedByUser = false
  @observable questionSets: IJobAdStore['questionSets'] = []
  @observable isOpenEditModal = false
  @observable jobAdMode?: IJobAdStore['jobAdMode'] = undefined
  @observable isOpenJobAdRepostConfirmationModal = false
  jobsAdsListStore: IJobAdStore['jobsAdsListStore']

  setJobsAdsListStore: IJobAdStore['setJobsAdsListStore'] = (jobsAdsStore) => {
    this.jobsAdsListStore = jobsAdsStore
  }

  @action
  openJobAdRepostConfirmationModal: IJobAdStore['openJobAdRepostConfirmationModal'] =
    () => {
      this.isOpenJobAdRepostConfirmationModal = true
    }

  @action
  closeJobAdRepostConfirmationModal: IJobAdStore['closeJobAdRepostConfirmationModal'] =
    () => {
      this.isOpenJobAdRepostConfirmationModal = false
    }

  @action
  openEditModal: IJobAdStore['openEditModal'] = (args) => {
    this.isOpenEditModal = true
    this.jobAdMode = args?.jobAdMode
  }

  @action
  closeEditModal: IJobAdStore['closeEditModal'] = () => {
    this.isOpenEditModal = false
    this.jobAdMode = undefined
  }

  @action
  setIsATSLocationClearedByUser: IJobAdStore['setIsATSLocationClearedByUser'] =
    (isATSLocationClearedByUser) => {
      this.isATSLocationClearedByUser = isATSLocationClearedByUser
    }

  @action
  setIsMediaTagRemoved: IJobAdStore['setIsMediaTagRemoved'] = (
    isMediaTagRemoved
  ) => {
    this.isMediaTagRemoved = isMediaTagRemoved
  }

  @action
  openReplaceDetailedLocationModalFromATS: IJobAdStore['openReplaceDetailedLocationModalFromATS'] =
    (locationFieldId) => {
      this.isOpenReplaceDetailedLocationModalFromATS = true
      this.currentLocationFieldIdForDetailedLocation = locationFieldId
    }

  @action
  closeReplaceDetailedLocationModalFromATS: IJobAdStore['closeReplaceDetailedLocationModalFromATS'] =
    () => {
      this.isOpenReplaceDetailedLocationModalFromATS = false
    }

  @action
  openDetailedLocationInputModal: IJobAdStore['openDetailedLocationInputModal'] =
    (locationFieldId) => {
      this.isOpenDetailedLocationInputModal = true
      this.currentLocationFieldIdForDetailedLocation = locationFieldId
    }

  @action
  closeDetailedLocationInputModal: IJobAdStore['closeDetailedLocationInputModal'] =
    () => {
      this.isOpenDetailedLocationInputModal = false
    }

  @action
  resetCurrentLocationFieldIdForDetailedLocation: IJobAdStore['resetCurrentLocationFieldIdForDetailedLocation'] =
    () => {
      this.currentLocationFieldIdForDetailedLocation = ''
    }

  @action
  setJobAds: IJobAdStore['setJobAds'] = (jobAds) => {
    this.jobAds = jobAds
  }

  @action
  startFetchingMediaTags: IJobAdStore['startFetchingMediaTags'] = () => {
    this.isFetchingMediaTags = true
  }

  @action
  stopFetchingMediaTags: IJobAdStore['stopFetchingMediaTags'] = () => {
    this.isFetchingMediaTags = false
  }

  @action
  startFetchingQestionSets: IJobAdStore['startFetchingQestionSets'] = () => {
    this.isFetchingQuestionSets = true
  }

  @action
  stopFetchingQestionSets: IJobAdStore['stopFetchingQestionSets'] = () => {
    this.isFetchingQuestionSets = false
  }

  @action
  openJobMediaTagSelectionModal: IJobAdStore['openJobMediaTagSelectionModal'] =
    () => {
      this.isOpenJobMediaTagSelectionModal = true
    }

  @action
  closeJobMediaTagSelectionModal: IJobAdStore['openJobMediaTagSelectionModal'] =
    () => {
      this.isOpenJobMediaTagSelectionModal = false
    }

  @action
  openJobQuestionSetSelectionModal: IJobAdStore['openJobQuestionSetSelectionModal'] =
    () => {
      this.isOpenJobQuestionSetSelectionModal = true
    }

  @action
  closeJobQuestionSetSelectionModal: IJobAdStore['openJobQuestionSetSelectionModal'] =
    () => {
      this.isOpenJobQuestionSetSelectionModal = false
    }

  @action
  setJobId = (jobId: number) => {
    this.jobId = jobId
  }

  @action
  private startFetchingRequiredLists = () => {
    this.isFetchingRequiredLists = true
  }

  @action
  private stopFetchingRequiredLists = () => {
    this.isFetchingRequiredLists = false
  }

  @action
  updateQuestionSets = (questionSets: Array<IQuestionSetsModel>) => {
    this.questionSets = questionSets.map(
      (questionSet) =>
        new QuestionSetsModel({
          id: questionSet.id,
          tag: questionSet.tag,
          is_selected: questionSet.isSelected,
          is_same: questionSet.isSame
        })
    )
  }

  @action
  updateMediaTags = (mediaTags: Array<IMediaTagsModel>) => {
    this.mediaTags = mediaTags.map(
      (tag) =>
        new MediaTagsModel({
          id: tag.id,
          hashtag: tag.hashtag,
          is_selected: tag.isSelected,
          image: tag.image,
          video: tag.video,
          is_same: tag.isSame
        })
    )
  }

  @action
  updateStandAloneStoreFields = (jobAds: IJobAdsDetailModel | null) => {
    if (!jobAds) {
      return
    }

    standAloneStore.locationFieldIds = jobAds.locations.map(
      (locationModel) => locationModel.fieldId
    )

    jobAds.locations.forEach((locationModel, index) => {
      standAloneStore.updateLocationRecord({
        fieldId: standAloneStore.locationFieldIds[index],
        parentLocationId: String(locationModel.location),
        hasSubLocation: rootStore.agency.hasSubLocation,
        detailedLocation: {
          allCountries: null,
          allStates: null,
          allCities: null,
          selectedFromAllCountry: null,
          selectedFromAllState: null,
          postalCode: null
        }
      })
    })

    if (jobAds.category !== null) {
      standAloneStore.updateSelectedCategory(jobAds.category)
    }
  }

  @action
  fetchRequiredLists = async () => {
    try {
      this.startFetchingRequiredLists()

      await standAloneStore.fetchLists({
        excludeList: ['candidateSkills']
      })
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.stopFetchingRequiredLists()
    }
  }

  @computed
  get displayInternalRewardFee(): boolean {
    return (
      rootStore.agency.allowInternalJobboard && this.jobAds?.rewardFeeInternal
    )
  }

  @action
  setPartnerActionExternalJobAdDetails: IJobAdStore['setPartnerActionExternalJobAdDetails'] =
    (details) => {
      this.partnerActionExternalJobAdDetails = details
    }

  @action
  resetPartnerActionExternalJobAdDetails: IJobAdStore['resetPartnerActionExternalJobAdDetails'] =
    () => {
      this.partnerActionExternalJobAdDetails = null
    }

  @action
  updateJobDetails: IJobAdStore['updateJobDetails'] = async (form) => {
    const data = { ...form.data }

    const dataTobeSentToServer: Partial<JobAdApiSpec> = {
      title: data.title,
      description: wrapIframesWithDiv(data.description),
      summary: data.summary,
      tags: data.tags,
      question_set_tag: data.question_set_tag,
      media_tag: data.media_tag,
      locations: standAloneStore.locationFieldIds
        .filter((fieldId) => data[`location_${fieldId}`] !== '')
        .map((fieldId, index) => {
          return {
            id: Number(
              data[`subLocation_${fieldId}`] || data[`location_${fieldId}`]
            ),
            detailed_location:
              index === 0 && this.isATSLocationClearedByUser
                ? {
                    country: null
                  }
                : {
                    ...(data[`detailedLocationCity_${fieldId}`] && {
                      city: data[`detailedLocationCity_${fieldId}`].id
                    }),
                    ...(data[`detailedLocationState_${fieldId}`] && {
                      state: data[`detailedLocationState_${fieldId}`].id
                    }),

                    ...(data[`detailedLocationCountry_${fieldId}`] && {
                      country: data[`detailedLocationCountry_${fieldId}`].id
                    }),
                    ...(data[`detailedLocationPostalCode_${fieldId}`] && {
                      postal_code: data[`detailedLocationPostalCode_${fieldId}`]
                    })
                  }
          }
        }),
      consultant: Number(data.consultant),
      category: data.sub_category
        ? Number(data.sub_category)
        : Number(data.category),
      worktype: Number(data.worktype),
      ...(data.salary_rate_min && {
        salary_rate_min: data.salary_rate_min
      }),
      ...(data.salary_rate_max && {
        salary_rate_max: data.salary_rate_max
      }),
      ...(data.salary_currency && {
        salary_currency: data.salary_currency
      }),
      salary_rate_period: data.salary_rate_period.toLowerCase(),
      salary_visibility: data.salary_visibility === '1' ? true : false,
      ...(data.reward_fee && {
        reward_fee: data.reward_fee,
        reward_fee_currency: data.reward_fee_currency
      }),
      ...(data.reward_fee_internal && {
        reward_fee_internal: data.reward_fee_internal,
        reward_fee_currency_internal: data.reward_fee_currency_internal
      })
    }

    try {
      const response = await API.patchData(
        ApiRoutes.standalone.jobAds.update(this.jobId),
        dataTobeSentToServer
      )

      if (response.status === 200) {
        toast.success(alertMessages.jobAdsEdit.success)

        standAloneStore.resetLocationFieldIds()
        standAloneStore.resetLocationRecord()

        this.closeEditModal()

        if (this.jobsAdsListStore) {
          // @ts-ignore TODO:: fix list store types
          this.jobsAdsListStore.setData('isFetching', true)

          if (this.partnerActionExternalJobAdDetails) {
            // @ts-ignore TODO:: fix list store types
            this.jobsAdsListStore.setData('additionalFilters', {
              external_ad_id:
                this.partnerActionExternalJobAdDetails.externalJobAdId
            })

            this.jobsAdsListStore.filtersToBeRemoved = ['external_id']
          }
          // @ts-ignore TODO:: fix list store types
          await this.jobsAdsListStore.fetchListData({
            refresh: true
          })

          if (this.partnerActionExternalJobAdDetails) {
            this.resetPartnerActionExternalJobAdDetails()
          }
        }
      } else {
        toast.error(alertMessages.jobAdsEdit.error)
      }
    } catch (error) {
      Logger.error(error as any)

      toast.error(alertMessages.jobAdsEdit.error)
    }
  }

  @action
  repostJobAdvert: IJobAdStore['repostJobAdvert'] = async (form, { cb }) => {
    const data = { ...form.data }

    const dataTobeSentToServer: Partial<JobAdApiSpec> = {
      title: data.title,
      description: wrapIframesWithDiv(data.description),
      summary: data.summary,
      tags: data.tags,
      question_set_tag: data.question_set_tag,
      media_tag: data.media_tag,
      locations: standAloneStore.locationFieldIds
        .filter((fieldId) => data[`location_${fieldId}`] !== '')
        .map((fieldId, index) => {
          return {
            id: Number(
              data[`subLocation_${fieldId}`] || data[`location_${fieldId}`]
            ),
            detailed_location:
              index === 0 && this.isATSLocationClearedByUser
                ? {
                    country: null
                  }
                : {
                    ...(data[`detailedLocationCity_${fieldId}`] && {
                      city: data[`detailedLocationCity_${fieldId}`].id
                    }),
                    ...(data[`detailedLocationState_${fieldId}`] && {
                      state: data[`detailedLocationState_${fieldId}`].id
                    }),

                    ...(data[`detailedLocationCountry_${fieldId}`] && {
                      country: data[`detailedLocationCountry_${fieldId}`].id
                    }),
                    ...(data[`detailedLocationPostalCode_${fieldId}`] && {
                      postal_code: data[`detailedLocationPostalCode_${fieldId}`]
                    })
                  }
          }
        }),
      consultant: Number(data.consultant),
      category: data.sub_category
        ? Number(data.sub_category)
        : Number(data.category),
      worktype: Number(data.worktype),
      ...(data.salary_rate_min && {
        salary_rate_min: data.salary_rate_min
      }),
      ...(data.salary_rate_max && {
        salary_rate_max: data.salary_rate_max
      }),
      ...(data.salary_currency && {
        salary_currency: data.salary_currency
      }),
      salary_rate_period: data.salary_rate_period.toLowerCase(),
      salary_visibility: data.salary_visibility === '1' ? true : false,
      ...(data.reward_fee && {
        reward_fee: data.reward_fee,
        reward_fee_currency: data.reward_fee_currency
      }),
      ...(data.reward_fee_internal && {
        reward_fee_internal: data.reward_fee_internal,
        reward_fee_currency_internal: data.reward_fee_currency_internal
      }),
      posted_at: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      created_at: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      expired_at: moment(data.expired_at).format()
    }

    try {
      const response = await API.postData(
        ApiRoutes.standalone.jobAds.repost(this.jobId),
        dataTobeSentToServer
      )

      if (response.status === 200) {
        toast.success(alertMessages.jobAdsRepost.success)

        standAloneStore.resetLocationFieldIds()
        standAloneStore.resetLocationRecord()

        this.closeEditModal()

        if (this.jobsAdsListStore) {
          // @ts-ignore TODO:: fix list store types
          this.jobsAdsListStore.setData('isFetching', true)

          if (this.partnerActionExternalJobAdDetails) {
            // @ts-ignore TODO:: fix list store types
            this.jobsAdsListStore.setData('additionalFilters', {
              external_ad_id:
                this.partnerActionExternalJobAdDetails.externalJobAdId
            })

            this.jobsAdsListStore.filtersToBeRemoved = ['external_id']
          }
          // @ts-ignore TODO:: fix list store types
          await this.jobsAdsListStore.fetchListData({
            refresh: true
          })

          if (this.partnerActionExternalJobAdDetails) {
            this.resetPartnerActionExternalJobAdDetails()
          }

          cb && cb()
        }
      } else {
        toast.error(alertMessages.jobAdsRepost.error)
      }
    } catch (error) {
      Logger.error(error as any)

      const errorMessages = generateErrorMessageFromFieldErrors(
        // @ts-ignore need to fix types for error
        error?.data,
        form.fields
      )
      if (errorMessages.length > 0) {
        errorMessages.map((message) => {
          toast.error(message)
        })
      } else {
        toast.error(alertMessages.jobAdsRepost.error)
      }
    }
  }

  @action
  fetchMediaTags: IJobAdStore['fetchMediaTags'] = async (searchTag) => {
    try {
      this.startFetchingMediaTags()

      const response: APIResponse<
        RefariDTOTypes['/standalone/job-ads/{id}/media-tags/']['get']['responses']['200']['content']['application/json']
      > = await API.getData(
        ApiRoutes.standalone.jobAds.mediaTags(this.jobId, searchTag || '')
      )

      const responseData = response.data

      if (!responseData) {
        throw new Error("FetchMediaTags API Response:: doesn't contain data")
      }

      runInAction(() => {
        this.mediaTags = responseData.results.map(
          (item) => new MediaTagsModel(item)
        )
      })
    } catch (error) {
      Logger.error(error as any)

      toast.error('Job media tags fetching failed. Please try again')
    } finally {
      this.stopFetchingMediaTags()
    }
  }

  @action
  fetchQestionSets: IJobAdStore['fetchQestionSets'] = async (searchTag) => {
    try {
      this.startFetchingQestionSets()

      const response: APIResponse<
        RefariDTOTypes['/standalone/job-ads/{id}/question-sets/']['get']['responses']['200']['content']['application/json']
      > = await API.getData(
        ApiRoutes.standalone.jobAds.questionSets(this.jobId, searchTag || '')
      )

      const responseData = response.data

      if (!responseData) {
        throw new Error("FetchQuestionSets API Response:: doesn't contain data")
      }

      runInAction(() => {
        this.questionSets = responseData.results.map(
          (item) => new QuestionSetsModel(item)
        )
      })
    } catch (error) {
      Logger.error(error as any)

      toast.error('Question sets tags fetching failed. Please try again')
    } finally {
      this.stopFetchingQestionSets()
    }
  }

  @action
  expireJobAdvert = async (jobAdvertID: string) => {
    try {
      if (!jobAdvertID) throw new Error()
      // eslint-disable-next-line no-undef
      const response: Awaited<
        AxiosPromise<
          | RefariDTOTypes['/standalone/job-ads/{id}/']['delete']['responses']['404']['content']['application/json']
          | RefariDTOTypes['/standalone/job-ads/{id}/']['delete']['responses']['200']['content']['application/json']
        >
      > = await API.deleteData(ApiRoutes.standalone.jobAds.update(jobAdvertID))

      if (response.status === 200) {
        toast.success(
          // @ts-ignore need to implement proper type check for these union types
          response.data?.message ? response.data.message : 'Job Advert Expired'
        )
        return
      }
      if (axios.isAxiosError(response) && response.response?.status === 404) {
        throw new Error((response.response.data as Record<string, any>).detail)
      }
      throw new Error('Something went wrong try again later.')
    } catch (error) {
      Logger.error(error as any)
      if (error instanceof Error) {
        toast.error(error.message)
      } else {
        toast.error('Something went wrong try again later.')
      }
    }
  }

  @computed
  get isAddressFromATSEdited(): boolean {
    return this.jobAds?.locations?.[0]?.detailedLocation !== null
  }

  @computed
  get isATSAddressEmpty(): boolean {
    return this.isAddressFromATSEdited ? true : !this.jobAds?.workAddress
  }

  @computed
  get workAddressStatus(): IJobAdStore['workAddressStatus'] {
    const workAddress = this.jobAds?.workAddress

    if (workAddress) {
      const workAddressValues = [
        workAddress.city,
        workAddress.state,
        workAddress.country,
        workAddress.postal_code
      ]

      const filtedWorkAddressValues = workAddressValues.filter((value) => value)

      if (filtedWorkAddressValues.length === workAddressValues.length) {
        return 'complete'
      } else if (filtedWorkAddressValues.length === 0) {
        return 'none'
      } else {
        return 'partialComplete'
      }
    }

    return 'none'
  }

  validateReferralRewardFee = async (
    value: number,
    currency: string,
    isInternal?: boolean
  ) => {
    try {
      const response = await API.postData(
        ApiRoutes.standalone.jobAds.validateReferralRewardFee,
        {
          value,
          currency,
          is_internal: isInternal
        }
      )
      if (response.status === 200) {
        return 'success'
      } else {
        return "Can't validate referral reward fee"
      }
    } catch (error) {
      // @ts-ignore set types for error
      const data = error?.data
      return data?.value?.[0] || data?.currency?.[0]
    }
  }
}

export default new JobAdStore()
