import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'

import { createBrowserHistory } from 'history'
import { ApiRoutes } from './Urls'
import AuthSession from './AuthSession'
import routesUrls from '../router/routesUrls'
import RootStore from '../stores/RootStore'

axios.defaults.headers = {}
axios.defaults.headers['Content-Type'] = 'application/json'
axios.defaults.baseURL = ApiRoutes.baseURL
axios.defaults.withCredentials = true

console.log('for static check CI testing')

// Add a response interceptor for 500
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      createBrowserHistory().replace(routesUrls.serverError.link)
      RootStore.resetAgencyBranding()
    }
    return Promise.reject(error)
  }
)

// Add a response interceptor for 401
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      AuthSession.remove()
      AuthSession.setGlobal(false, 'RefariLoggedIn')
      // @ts-ignore see https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
      window.location.reload(true)
    }
    if (error.response && error.response.status === 400) {
      return Promise.reject(error.response)
    }
    if (error.response && error.response.status === 429) {
      return Promise.reject(error.response)
    }
    if (error.response && error.response.status === 403) {
      window.location.href = window.location.origin
    }
    return error
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    try {
      if (error.response.data.detail.toLowerCase().includes('invalid token')) {
        AuthSession.remove()
        // @ts-ignore see https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
        window.location.reload(true)
      }
    } catch (e) {
      // TODO:: Handle error
    }
    return Promise.reject(error)
  }
)

type Request = {
  url: string
  params: any
}

type APIType = {
  getData(
    url: string,
    params?: any,
    headers?: AxiosRequestConfig['headers']
  ): AxiosPromise<any>
  getAllData(requests: Request[]): Promise<any>
  postData(url: string, data?: any): AxiosPromise<any>
  postAllData(requests: Request[]): Promise<any>
  patchData(url: string, data: any): AxiosPromise<any>
  putData(url: string, data: any): AxiosPromise<any>
  deleteData(url: string, data?: any): AxiosPromise<any>
}

const API: APIType = {
  getData(url, params, headers) {
    return axios({
      method: 'GET',
      url,
      params,
      headers: headers
    })
  },

  getAllData(requests) {
    const promises = requests.map((request) =>
      API.getData(request.url, request.params)
    )

    return axios.all(promises).then(axios.spread((...args) => args))
  },

  postData(url, data) {
    return axios({
      method: 'POST',
      url,
      data
    })
  },

  postAllData(requests) {
    const promises = requests.map((request) =>
      API.postData(request.url, request.params)
    )
    return axios.all(promises).then(axios.spread((...args) => args))
  },

  patchData(url, data) {
    return axios({
      method: 'PATCH',
      url,
      data
    })
  },

  putData(url, data) {
    return axios({
      method: 'PUT',
      url,
      data
    })
  },

  deleteData(url, data) {
    return axios({
      method: 'DELETE',
      url,
      data
    })
  }
}

export default API
