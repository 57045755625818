/* eslint-disable no-plusplus */
import { computed } from 'mobx'
import FieldsGroup from '../form/FieldsGroup'
import { validationErrors as errors } from '../../utils/helpers'
import { availabilityUnit, salaryPeriod } from '../../constants/mapping'
import Field from '../form/Field'

export default class Questions {
  constructor(data, questionNo, isForRegistration) {
    if (!data) data = {}
    this.id = data.id
    this.jobs = data.jobs
    this.questionNo = questionNo
    this.prefix = data.prefix
    this.example = data.example
    this.answerType = data.answer_type
    this.answerValues =
      data.answer_type === 'integer' ||
      data.answer_type === 'text' ||
      data.answer_type === 'heading' ||
      data.answer_type === 'file'
        ? data.answer_values || ''
        : data.answer_values || []
    this.maxLength = data.max_length || 0
    this.isRequired = data.is_required || false
    this.multiSelect = data.multi_select || false
    this.additionalText = data.additional_text || ''
    this.radioButton = data.radio_button || false
    this.checkBox = data.check_box || false
    this.status = data.status
    this.question = data.question
    this.mapping = data.mapping
    this.isCandidateUpdating = false
    this.isImported = data.is_imported !== undefined ? data.is_imported : true
    this.isApplicationUpdating = false
    this.isSettingUpdating = false
    this.showCandidateSubRecord = !!this.selectedSubCandidate
    this.showApplicationSubRecord = !!this.selectedSubApplication
    this.candidateSubRecordOptions = this.subCandidateOptions
    this.applicationSubRecordOptions = this.subApplicationOptions
    this.selectedCandidateId = this.selectedCandidate
    this.selectedApplicationId = this.selectedApplication
    this.isForRegistration = isForRegistration || false
    this.getFileData = null
  }

  @computed
  get selectedCandidate() {
    if (!this.mapping || (this.mapping && this.mapping.length < 1)) return ''
    let id = ''
    for (let i = 0; i < this.mapping.length; i++) {
      if (this.mapping[i].map_type === 'candidate') {
        id = this.mapping[i].external_field_id
      }
    }
    return id
  }

  @computed
  get selectedApplication() {
    if (!this.mapping || (this.mapping && this.mapping.length < 1)) return ''
    let id = ''
    for (let i = 0; i < this.mapping.length; i++) {
      if (this.mapping[i].map_type === 'application') {
        id = this.mapping[i].external_field_id
      }
    }
    return id
  }

  @computed
  get selectedSubCandidate() {
    if (!this.mapping || (this.mapping && this.mapping.length < 1)) return ''
    let id = ''
    for (let i = 0; i < this.mapping.length; i++) {
      if (this.mapping[i].map_type === 'candidate') {
        if (
          this.mapping[i].external_field_id === 'availability.relative.period'
        ) {
          id = this.mapping[i].availability_unit
        } else if (
          this.mapping[i].external_field_id ===
            'employment.current.salary.rate' ||
          this.mapping[i].external_field_id ===
            'employment.ideal.salary.rateLow' ||
          this.mapping[i].external_field_id ===
            'employment.ideal.salary.rateHigh'
        ) {
          id = this.mapping[i].salary_period
        }
      }
    }
    return id
  }

  @computed
  get selectedSubApplication() {
    if (!this.mapping || (this.mapping && this.mapping.length < 1)) return ''
    let id = ''
    for (let i = 0; i < this.mapping.length; i++) {
      if (this.mapping[i].map_type === 'application') {
        if (
          this.mapping[i].external_field_id === 'availability.relative.period'
        ) {
          id = this.mapping[i].availability_unit
        } else if (
          this.mapping[i].external_field_id ===
            'employment.current.salary.rate' ||
          this.mapping[i].external_field_id ===
            'employment.ideal.salary.rateLow' ||
          this.mapping[i].external_field_id ===
            'employment.ideal.salary.rateHigh'
        ) {
          id = this.mapping[i].salary_period
        }
      }
    }
    return id
  }

  @computed
  get subCandidateOptions() {
    let subRecordOptions = []
    if (this.selectedCandidate === 'availability.relative.period') {
      subRecordOptions = availabilityUnit
    } else if (
      this.selectedCandidate === 'employment.current.salary.rate' ||
      this.selectedCandidate === 'employment.ideal.salary.rateLow' ||
      this.selectedCandidate === 'employment.ideal.salary.rateHigh'
    ) {
      subRecordOptions = salaryPeriod
    }
    return subRecordOptions
  }

  @computed
  get subApplicationOptions() {
    let subRecordOptions = []
    if (this.selectedApplication === 'availability.relative.period') {
      subRecordOptions = availabilityUnit
    } else if (
      this.selectedApplication === 'employment.current.salary.rate' ||
      this.selectedApplication === 'employment.ideal.salary.rateLow' ||
      this.selectedApplication === 'employment.ideal.salary.rateHigh'
    ) {
      subRecordOptions = salaryPeriod
    }
    return subRecordOptions
  }

  @computed
  get mappingForm() {
    return new FieldsGroup({
      candidateRecord: new Field({
        name: 'candidateRecord',
        value: this.selectedCandidate
      }),
      applicationRecord: new Field({
        name: 'applicationRecord',
        value: this.selectedApplication
      }),
      candidateSubRecord: new Field({
        name: 'candidateSubRecord',
        value: this.selectedSubCandidate,
        validators: [errors.required()]
      }),
      applicationSubRecord: new Field({
        name: 'applicationSubRecord',
        value: this.selectedSubApplication,
        validators: [errors.required()]
      }),
      prefix: new Field({
        name: 'prefix',
        value: this.prefix
      }),
      example: new Field({
        name: 'example',
        value: this.example
      }),
      answerType: new Field({
        name: 'answerType',
        value: this.answerType,
        validators: [errors.required()]
      })
    })
  }
}
