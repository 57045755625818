export default {
  renameTemplateMessages: "Template's name was successfully updated!",
  deleteTemplateMessages: 'Template was successfully deleted!',
  dataEraserEmailSent: 'Data erasure request has been sent successfully!',
  dataEraserDeleteImmediately: 'Data has been deleted successfully!',
  profileUpdated: 'Your profile was successfully updated!',
  profileDeleted: 'Your profile was successfully deleted!',
  agencySettingsUpdated: 'Your settings were successfully updated!',
  triggerUpdated: 'Trigger has been successfully updated!',
  progressionStatusUpdated: 'Progression status has been successfully updated!',
  placementsUpdated: 'Your placements have been scheduled to send!',
  refreshPlacements: 'Your placements have been scheduled to import.',
  submitPublicResponse: 'Public response was successfully submitted',
  submitPrivateResponse: 'Private response was successfully submitted',
  testimonialRevisionResponse:
    'Testimonial review request successfully submitted',
  deletePublicResponse: 'Public response was successfully deleted!',
  subscription: 'Subscription link was successfully copied!',
  socialise: 'Job link was successfully copied!',
  successfulSuggestion: (email) =>
    `You have successfully suggested a job alert to ${email}.`,
  successfulSubscription: 'Job alert is active',
  signin: 'You have successfully signed in.',
  signinAs: (name, email) =>
    `You have successfully signed in as ${name} (${email}).`,
  emailVerified: 'Your email successfully verified.',
  notValidated:
    'There was an error trying to validate your form. Please try again later.',
  error: 'Something went wrong.',
  setRating: (rating) => `Successfully rated as ${rating}!`,
  report: 'You have successfully reported to this referrer',
  intervalChanged: 'The interval of job alert was successfully changed',
  unsubscribe: 'Subscription has been removed',
  submissionMessages: 'The message template has been added successfully',
  updateMessageTemplateSuccess:
    'The message template has been successfully updated!',
  deleteSubmissionMessages: 'The message template was successfully deleted!',
  customBackgroundRemove: 'Custom background has been removed',
  customBackgroundUpdated: 'Custom background was successfully updated!',
  customBackgroundAdded: 'New custom background has been added successfully',
  companyWasActivated: (name) => `${name} was activated.`,
  companyWasDeactivated: (name) => `${name} was deactivated.`,
  inviteReferrer: (email) => `You have successfully invited ${email}.`,
  changedAgency: (name) => `You have successfully switched to board "${name}".`,
  defaultQuestionSet: (defaultQSname) =>
    `Are you sure you wish to set this as default screen question set? This would replace the current active default question set '${defaultQSname}'`,
  requestDemo: 'You have successfully requested a personal demo.',
  rewardFeeUpdated: 'Reward fee amount was successfully updated.',
  labelUpdated: 'Label was successfully updated.',
  labelDeleted: 'Label was successfully Deleted',
  rewardMultiplierUpdated: 'Reward multiplier was successfully updated.',
  invoiceRejected: "You've rejected current invoice",
  addCard: 'You have successfully added card.',
  replaceCard: 'You have successfully replace your card.',
  addRecruiter: 'You have successfully added company user.',
  updateBilling: 'Billing details was successfully updated.',
  floatingLink: 'You have successfully added link.',
  hashtagsAdd: 'Hashtag was successfully added.',
  hashtagsUpdate: 'Hashtag was successfully updated.',
  hashtagsRemoved: 'Hashtag was successfully removed.',
  mappingUpdate: 'Mapping was successfully updated.',
  questionUpdate: 'Question was successfully updated.',
  questionAdded: 'Question was successfully added.',
  questionRemoved: 'Question was successfully removed.',
  questionCloned: 'Question was successfully cloned',
  questionSetAdded: 'Question set was successfully added.',
  questionSetDefault: 'Question set was successfully set as default.',
  questionSetUpdate: 'Question set was successfully updated.',
  questionSetRemoved: 'Question set was successfully removed.',
  addXml: 'XML was successfully generated.',
  removeXml: 'XML was successfully removed.',
  updateXml: 'XML was successfully updated.',
  copyXml: 'XML was successfully copied.',
  cloneBackgroundSuccess: 'Custom Background Cloned Successfully',
  updateAgencyBudget: "You've successfully updated your agency budget.",
  updateRecruiterBudget: "You've successfully updated your recruiter budget.",
  socialize: (socialList, companiesAmount, name) => {
    let output

    output = `We are attempting to socialise this ${
      name || 'job'
    } to ${socialList.join(', ')}`
    switch (companiesAmount) {
      case 0:
        break
      case 1:
        output += ` ${
          socialList.length > 0 ? 'and ' : ''
        }one network company page`
        break
      default:
        output += ` ${
          socialList.length > 0 ? 'and ' : ''
        }${companiesAmount} network companies page`
        break
    }
    return output
  },
  codeSent: 'Verification code has been sent',
  jobAdsEdit: {
    success: 'This job advert is successfully updated.',
    error: 'There is an error processing the request. Please try again'
  },
  jobAdsRepost: {
    success: 'This job advert is successfully reposted.',
    error: 'There is an error processing the request. Please try again'
  },
  genericErrorMessage:
    'There is an error processing the request. Please try again',
  candidateAd: {
    create: {
      success: 'Candidate Ad for the candidate is successfully created.'
    },
    update: {
      success: 'Candidate ad is successfully updated.'
    },
    repost: {
      success: 'Candidate ad is reposted successfully.'
    },
    expire: {
      success: 'Candidate ad is expired successfully.'
    }
  }
}
