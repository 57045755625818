import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import routesUrls from '../../../router/routesUrls'
import { baseUrls } from '../../../utils/Urls'
import LogoIcon from '../icons/LogoIcon'

const footerLinks = [
  {
    title: 'Contact us',
    to: 'https://refari.co/contact-us/'
  },
  {
    title: 'Terms of use',
    to: 'https://refari.co/terms-of-use/'
  },
  {
    title: 'Privacy Policy',
    to: 'https://refari.co/privacy-policy/'
  },
]

const socials = [
  {
    name: 'LINK_FACEBOOK',
    title: 'facebook',
    icon: `${baseUrls.static}icon-facebook-original.png`,
  },
  {
    name: 'LINK_GOOGLE',
    title: 'google',
    icon: `${baseUrls.static}icon-google-plus-original.png`,
  },
  {
    name: 'LINK_LINKEDIN',
    title: 'linkedin',
    icon: `${baseUrls.static}icon-linkedin-original.png`,
  },
  {
    name: 'LINK_TWITTER',
    title: 'twitter',
    icon: `${baseUrls.static}icon-twitter-original.png`,
  },
  {
    name: 'LINK_YOUTUBE',
    title: 'youtube',
    icon: `${baseUrls.static}icon-youtube-original.png`,
  },
]

const Footer = ({
  theme, socialLinks, location, indexStatus, loggedIn
}) => {
  const isHome = (location.pathname === routesUrls.home.path || location.pathname === routesUrls.signIn.link || location.pathname === `${routesUrls.signIn.link}/`)
  const styles = {
    colorWhite: { color: theme.palette.whiteTextColor },
    footerTop: {
      backgroundColor: isHome
        ? 'transparent'
        : theme.palette.primary1Color,
      transitionDuration: isHome ? '0s' : '0.3s'
    },
    footerBottom: {
      backgroundColor: isHome
        ? 'transparent'
        : theme.palette.primary1Color,
      transitionDuration: isHome ? '0s' : '0.3s'
    },
  }

  return (
    <>
      <div
        style={styles.footerTop}
        className={classnames('footer footer-top', { 'left-space': indexStatus })}
      >
        <div className="container">
          <div className="row between-sm middle-sm start-sm center-xs">
            <div
              className="col-sm-1 col-md-2 col-lg-3 col-xs-12"
            >
              {
                loggedIn && (
                  <Link to={routesUrls.home.link}>
                    <LogoIcon style={{ width: 60, height: 34 }} />
                  </Link>
                )
              }
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="row between-sm">
                {
                  footerLinks.map((link) => <a
                    style={styles.colorWhite}
                    className="col-sm col-xs-12 footer-link"
                    key={link.title}
                    href={link.to}
                    target="_blank"
                  >
                    {link.title}
                  </a>)
                }
              </div>
            </div>
            <div className="col-sm-1 col-md-2 col-lg-3 col-xs-12">
              <div className="row end-sm">
                {
                socialLinks && !!Object.keys(socialLinks).length
                && (<div className="col-sm col-xs-12 social-links">
                  {
                  socials.filter((socialLink) => !!socialLinks[socialLink.name]).map((socialLink) => (
                    <a
                      href={socialLinks[socialLink.name]}
                      key={socialLink.name}
                      title={socialLink.title}
                    >
                      <img src={socialLink.icon} alt={socialLink.title} />
                    </a>
                  ))
                }
                </div>)
              }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classnames('footer footer-bottom center-xs', { 'left-space': indexStatus })}
        style={styles.footerBottom}
      >
        © {new Date().getFullYear()} refari – All Rights Reserved
      </div>
    </>
  )
}

export default withTheme((withRouter(Footer)))
