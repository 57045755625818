/* eslint-disable */
// @ts-nocheck TODO:: fix types and then remove comment
import * as validator from 'validator'
import qp from 'query-parse'
import humanizeDuration from 'humanize-duration'
import * as moment from 'moment'
import { contrastColor } from 'contrast-color'
import { hex } from 'wcag-contrast'
import msg from '../../constants/validationErrorMessages'
import capitalize from 'lodash.capitalize'

const upperCaseRegex = /^[A-Z]$/
const lowerCaseRegex = /^[a-z]$/
const numberRegex = /^[0-9]$/
const symbolRegex = /^[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]$/
const mulTable = [
  512, 512, 456, 512, 328, 456, 335, 512, 405, 328, 271, 456, 388, 335, 292,
  512, 454, 405, 364, 328, 298, 271, 496, 456, 420, 388, 360, 335, 312, 292,
  273, 512, 482, 454, 428, 405, 383, 364, 345, 328, 312, 298, 284, 271, 259,
  496, 475, 456, 437, 420, 404, 388, 374, 360, 347, 335, 323, 312, 302, 292,
  282, 273, 265, 512, 497, 482, 468, 454, 441, 428, 417, 405, 394, 383, 373,
  364, 354, 345, 337, 328, 320, 312, 305, 298, 291, 284, 278, 271, 265, 259,
  507, 496, 485, 475, 465, 456, 446, 437, 428, 420, 412, 404, 396, 388, 381,
  374, 367, 360, 354, 347, 341, 335, 329, 323, 318, 312, 307, 302, 297, 292,
  287, 282, 278, 273, 269, 265, 261, 512, 505, 497, 489, 482, 475, 468, 461,
  454, 447, 441, 435, 428, 422, 417, 411, 405, 399, 394, 389, 383, 378, 373,
  368, 364, 359, 354, 350, 345, 341, 337, 332, 328, 324, 320, 316, 312, 309,
  305, 301, 298, 294, 291, 287, 284, 281, 278, 274, 271, 268, 265, 262, 259,
  257, 507, 501, 496, 491, 485, 480, 475, 470, 465, 460, 456, 451, 446, 442,
  437, 433, 428, 424, 420, 416, 412, 408, 404, 400, 396, 392, 388, 385, 381,
  377, 374, 370, 367, 363, 360, 357, 354, 350, 347, 344, 341, 338, 335, 332,
  329, 326, 323, 320, 318, 315, 312, 310, 307, 304, 302, 299, 297, 294, 292,
  289, 287, 285, 282, 280, 278, 275, 273, 271, 269, 267, 265, 263, 261, 259
]

const shgTable = [
  9, 11, 12, 13, 13, 14, 14, 15, 15, 15, 15, 16, 16, 16, 16, 17, 17, 17, 17, 17,
  17, 17, 18, 18, 18, 18, 18, 18, 18, 18, 18, 19, 19, 19, 19, 19, 19, 19, 19,
  19, 19, 19, 19, 19, 19, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
  20, 20, 20, 20, 20, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21,
  21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 22, 22, 22, 22, 22, 22,
  22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22,
  22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 23, 23, 23, 23, 23, 23, 23,
  23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23,
  23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23,
  23, 23, 23, 23, 23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24,
  24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24,
  24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24,
  24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24,
  24, 24, 24, 24, 24, 24, 24
]

export const validationErrors = {
  required: () =>
    function (value) {
      return value !== undefined &&
        value !== null &&
        validator.isEmpty(value.toString().trim())
        ? msg.required
        : null
    },
  isEmail: () =>
    function (value) {
      return !validator.isEmail(value) ? msg.isEmail : null
    },
  isURL: () =>
    function (value) {
      return value && !validator.isURL(value) ? msg.isURL : null
    },
  linkedinProfileURL: () =>
    function (value) {
      if (!value) {
        return null
      }

      const symbolRegex =
        /^(?:http(?:s)?:\/\/)?(?:www\.|\w\w\.)?linkedin\.com\/((?:in)\/(?:[a-zA-Z0-9-]{5,30}(?:\/)?)|(?:profile\/)(?:view\?id=[0-9]+)|(pub\/[^\/]+\/((\w|\d)+\/?){3}))?$/
      if (!symbolRegex.test(value)) {
        return msg.isLinkedinProfileURL
      }

      return !validator.isURL(value) ? msg.isLinkedinProfileURL : null
    },
  linkedinCompanyURL: () =>
    function (value) {
      if (!value) {
        return null
      }

      const symbolRegex =
        /^(?:http(?:s)?:\/\/)?(?:www\.|\w\w\.)?linkedin\.com\/((?:company)\/(?:[a-zA-Z0-9-]{3,30}(?:\/)?))?$/
      if (!symbolRegex.test(value)) {
        return msg.isLinkedinCompanyURL
      }

      return !validator.isURL(value) ? msg.isLinkedinCompanyURL : null
    },
  password: () =>
    function (value) {
      if (!value) {
        return null
      }

      const symbolRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
      if (!symbolRegex.test(value)) {
        return msg.password
      }

      return !validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 3,
        minSymbols: 0,
        returnScore: false
      })
        ? msg.password
        : null
    },
  min: (minLength) =>
    function (value) {
      return value && value.length < minLength ? msg.min(minLength) : null
    },
  max: (maxLength) =>
    function (value) {
      return value && value.length > maxLength ? msg.max(maxLength) : null
    },
  maxFileSize: (maxFileSize, currentFileSize) =>
    maxFileSize * 1024 * 1024 < currentFileSize
}

export const timeHelpers = {
  timeDifference: (current, previous) => {
    const msPerMinute = 60 * 1000
    const msPerHour = msPerMinute * 60
    const msPerDay = msPerHour * 24
    const msPerMonth = msPerDay * 30
    const msPerYear = msPerDay * 365

    const elapsed = current - previous

    if (elapsed < msPerMinute) {
      return `${Math.round(elapsed / 1000)} seconds ago`
    }
    if (elapsed < msPerHour) {
      const count = Math.round(elapsed / msPerMinute)
      return `${count} ${count === 1 ? 'minute' : 'minutes'} ago`
    }
    if (elapsed < msPerDay) {
      const count = Math.round(elapsed / msPerHour)
      return `${count} ${count === 1 ? 'hour' : 'hours'}  ago`
    }
    if (elapsed < msPerMonth) {
      const count = Math.round(elapsed / msPerDay)
      return `about ${count} ${count === 1 ? 'day' : 'days'} ago`
    }
    if (elapsed < msPerYear) {
      const count = Math.round(elapsed / msPerMonth)
      return `about ${count} ${count === 1 ? 'month' : 'months'} ago`
    }
    return `about ${Math.round(elapsed / msPerYear)} years ago`
  }
}

export const analyzeString = (str) => {
  let charMap = countChars(str)
  let analysis = {
    length: str.length,
    uniqueChars: Object.keys(charMap).length,
    uppercaseCount: 0,
    lowercaseCount: 0,
    numberCount: 0,
    symbolCount: 0
  }
  Object.keys(charMap).forEach((char) => {
    /* istanbul ignore else */
    if (upperCaseRegex.test(char)) {
      analysis.uppercaseCount += charMap[char]
    } else if (lowerCaseRegex.test(char)) {
      analysis.lowercaseCount += charMap[char]
    } else if (numberRegex.test(char)) {
      analysis.numberCount += charMap[char]
    } else if (symbolRegex.test(char)) {
      analysis.symbolCount += charMap[char]
    }
  })
  return analysis
}

export const countChars = (str) => {
  let result = {}
  Array.from(str).forEach((char) => {
    let curVal = result[char]
    if (curVal) {
      result[char] += 1
    } else {
      result[char] = 1
    }
  })
  return result
}

export const isPasswordValidated = (value) => {
  if (!value) {
    return false
  }

  const symbolRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  if (!symbolRegex.test(value)) {
    return false
  }

  return validator.isStrongPassword(value, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 3,
    minSymbols: 0,
    returnScore: false
  })
}

export const helpers = {
  createUUID: () => {
    const s = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'

    return s.join('')
  },
  cleanArray: (arr) => {
    let newArray = []
    arr.forEach((item) => {
      if (item.trim()) {
        newArray = [...newArray, item]
      }
    })
    return newArray
  },
  removeSymbols: (value) => value.replace(/[^a-zA-Z0-9_]/g, ''),
  isObject: (value) => value && value.toString() === '[object Object]',
  removeTagSymbols: (value) => value.replace(/[^a-zA-Z0-9_-]/g, '')
}

export const truncate = (text, maxLength = 100) => {
  if (text.length > maxLength) {
    return `${text.substr(0, maxLength)}...`
  }
  return text
}

type pSBCOverloadFunction = {
  (p, c0, c1, l): string | null
  (p, c0): string | null
}
// Lighten the color according to percentage
// source https://stackoverflow.com/a/13542669
export const pSBC: pSBCOverloadFunction = (p, c0, c1, l) => {
  let r
  let g
  let b
  let P
  let f
  let t
  let h
  const i = parseInt
  const m = Math.round
  let a = typeof c1 === 'string'
  if (
    typeof p !== 'number' ||
    p < -1 ||
    p > 1 ||
    typeof c0 !== 'string' ||
    (c0[0] !== 'r' && c0[0] !== '#') ||
    (c1 && !a)
  ) {
    return null
  }
  const pSBCr = (d) => {
    let n = d.length
    const x = {}
    if (n > 9) {
      ;([r, g, b, a] = d = d.split(',')), (n = d.length)
      if (n < 3 || n > 4) return null
      x.r = i(r[3] === 'a' ? r.slice(5) : r.slice(4))
      x.g = i(g)
      x.b = i(b)
      x.a = a ? parseFloat(a) : -1
    } else {
      if (n === 8 || n === 6 || n < 4) return null
      if (n < 6) {
        d = `#${d[1]}${d[1]}${d[2]}${d[2]}${d[3]}${d[3]}${
          n > 4 ? d[4] + d[4] : ''
        }`
      }
      d = i(d.slice(1), 16)
      if (n === 9 || n === 5) {
        ;(x.r = (d >> 24) & 255),
          (x.g = (d >> 16) & 255),
          (x.b = (d >> 8) & 255),
          (x.a = m((d & 255) / 0.255) / 1000)
      } else {
        ;(x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1)
      }
    }
    return x
  }
  ;(h = c0.length > 9),
    (h = a ? (c1.length > 9 ? true : c1 === 'c' ? !h : false) : h),
    (f = pSBCr(c0)),
    (P = p < 0),
    (t =
      c1 && c1 !== 'c'
        ? pSBCr(c1)
        : P
        ? {
            r: 0,
            g: 0,
            b: 0,
            a: -1
          }
        : {
            r: 255,
            g: 255,
            b: 255,
            a: -1
          }),
    (p = P ? p * -1 : p),
    (P = 1 - p)
  if (!f || !t) return null
  if (l) {
    ;(r = m(P * f.r + p * t.r)),
      (g = m(P * f.g + p * t.g)),
      (b = m(P * f.b + p * t.b))
  } else {
    ;(r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
      (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
      (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5))
  }
  ;(a = f.a),
    (t = t.a),
    (f = a >= 0 || t >= 0),
    (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0)
  if (h) {
    return `rgb${f ? 'a(' : '('}${r},${g},${b}${
      f ? `,${m(a * 1000) / 1000}` : ''
    })`
  }
  return `#${(
    4294967296 +
    r * 16777216 +
    g * 65536 +
    b * 256 +
    (f ? m(a * 255) : 0)
  )
    .toString(16)
    .slice(1, f ? undefined : -2)}`
}

export const checkColorIsDark = (color) => {
  let r
  let g
  let b

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return false
  }
  return true
}

export const mapFormServerErrors = (data, fields) => {
  if (data) {
    Object.keys(fields).forEach((item) => {
      if (item === 'nonFieldErrors') {
        fields.nonFieldErrors.update(data[fields[item].name])
        return
      }

      if (Array.isArray(data[fields[item].name])) {
        // if inner data is object then it is nested data
        fields[item].setServerErrors(data[fields[item].name])
      } else {
        mapFormServerErrors(data[item], fields[item].fields)
      }
    })
  }
}

function replaceAll(str, search, replacement) {
  return str.replace(new RegExp(search, 'g'), replacement)
}

export const generateErrorMessageFromFieldErrors = (data, fields) => {
  let errors = []
  if (data) {
    Object.keys(fields).forEach((item) => {
      if (item === 'nonFieldErrors') {
        return
      }

      if (Array.isArray(data[fields[item].name])) {
        errors.push(
          `${capitalize(replaceAll(fields[item].name, '_', ' '))} : ${
            data[fields[item].name]?.[0]
          }`
        )
      }
    })
  }
  return errors
}

export const getDateFormat = (currency: string): string => {
  if (currency === 'USD') return 'MM/DD/yyyy'
  return 'DD/MM/yyyy'
}

export const utils = {
  formatter: (value) => (value.toString().length === 1 ? `0${value}` : value),
  isPrimitive: (value) => {
    const type = typeof value
    return value === null || (type !== 'object' && type !== 'function')
  },
  isNumeric: (value) => !isNaN(parseFloat(value)) && isFinite(value),
  delay: (() => {
    let timer = 0
    return (callback, ms = 0) => {
      clearTimeout(timer)
      timer = setTimeout(callback, ms)
    }
  })(),
  dateFormatter: (
    timestamp,
    separator = '/',
    reverse = false,
    currency = ''
  ) => {
    separator = separator.toString()
    const date = new Date(timestamp)

    const day = utils.formatter(date.getDate())
    const month = utils.formatter(date.getMonth() + 1)
    const year = date.getFullYear()
    if (reverse) {
      return year + separator + month + separator + day
    }
    if (currency === 'USD') {
      return month + separator + day + separator + year
    }
    return day + separator + month + separator + year
  },
  timeFormater: (timestamp, separator = ':', currency) => {
    if (currency === 'USD') {
      return moment(timestamp).format('LT')
    }
    const date = new Date(timestamp)
    separator = separator.toString()
    const hr = utils.formatter(date.getHours())
    const min = utils.formatter(date.getMinutes())
    return hr + separator + min
  },
  shortDurationHumanazer: humanizeDuration.humanizer({
    language: 'short',
    spacer: '',
    languages: {
      short: {
        y() {
          return 'y'
        },
        mo() {
          return 'mo'
        },
        w() {
          return 'w'
        },
        d() {
          return 'd'
        },
        h() {
          return 'h'
        },
        m() {
          return 'm'
        },
        s() {
          return 's'
        },
        ms() {
          return 'ms'
        }
      }
    }
  }),
  getTimeRemaining: (endtime) => {
    const t = Date.parse(endtime) - Date.parse(new Date())
    const seconds = Math.floor((t / 1000) % 60)
    const minutes = Math.floor((t / 1000 / 60) % 60)
    const hours = Math.floor((t / (1000 * 60 * 60)) % 24)
    const days = Math.floor(t / (1000 * 60 * 60 * 24))
    return {
      total: t,
      days,
      hours,
      minutes,
      seconds
    }
  },
  getValueWithArrayOfKeys: (data, keys) => {
    if (!Array.isArray(keys)) {
      throw new Error('argument "keys" should be an array')
    }

    let startFrom = 0

    function keyRecursion(data) {
      startFrom++
      switch (data) {
        case 'succeeded':
          return 'paid'
        case 'fail':
          return 'pending'
        default:
          return utils.isPrimitive(data)
            ? data
            : keyRecursion(data[keys[startFrom]])
      }
    }

    return keyRecursion(data[keys[startFrom]])
  },
  cleanArray: (arr) => {
    let newArray = []
    arr.forEach((item) => {
      if (item) {
        newArray = [...newArray, item]
      }
    })
    return newArray
  }
}

export const createUUID = () => {
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  return s.join('')
}

export const notifyPopupOpener = () => {
  // To notify widget, after twitter redirect
  // TODO: make more save
  if (window.opener) {
    const message = JSON.stringify(
      qp.toObject(window.location.search.replace('?', ''))
    )
    window.opener.postMessage(message, '*')
  }
}

export const throttle = function (func, ms) {
  let isThrottled = false
  let savedArgs
  let savedThis
  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments
      savedThis = this
      return
    }
    func.apply(this, arguments)
    isThrottled = true
    setTimeout(() => {
      isThrottled = false
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs)
        savedArgs = savedThis = null
      }
    }, ms)
  }
  return wrapper
}

export const debounce = function debounce(func, wait) {
  let timeout
  return function (...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}

export const numberFormatter = (num, decim = 2) => {
  switch (typeof num) {
    case 'number':
      return num.toFixed(decim).toLocaleString('en-US')
    case 'string':
      return parseFloat(num).toFixed(decim).toLocaleString('en-US')
    default:
      return num
  }
}

Number.prototype.toFixedNoRounding = function (n) {
  const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${n}})?`, 'g')
  const a = this.toString().match(reg)[0]
  const dot = a.indexOf('.')
  if (dot === -1) {
    return `${a}.${'0'.repeat(n)}`
  }
  const b = n - (a.length - dot) + 1
  return b > 0 ? a + '0'.repeat(b) : a
}

export const withCurrencySign = (amount, currencySign) =>
  `${currencySign.prefix ? currencySign.prefix : ''}${amount}${
    currencySign.suffix ? currencySign.suffix : ''
  }`

export const removeSymbols = (value) => value.replace(/[^a-zA-Z0-9_]/g, '')

export const selectedFields = (field) => {
  const data = []
  field.forEach((el) => {
    if (el.selected) {
      data.push(el.label)
    }
    if (el.children.data && el.children.data.length) {
      if (el.children.data.every((item) => item.selected)) {
        el.applyFilter()
        if (!data.includes(el.label)) {
          data.push(el.label)
        }
        return data
      }
      if (el.children.data.some((item) => !item.selected)) {
        const indexLabel = data.findIndex((label) => label === el.label)
        if (indexLabel > -1) {
          data.splice(indexLabel, 1)
        }
        el.setStatus(false)
      }
      el.children.data.forEach((item) => {
        if (item.selected) {
          data.push(item.label)
        }
      })
    }
  })
  return data
}

export const selectedFieldsById = (field) => {
  const data = []
  field.forEach((el) => {
    if (el.selected) {
      data.push(el.key)
    }
    if (el.children.data && el.children.data.length) {
      el.children.data.forEach((item) => {
        if (item.selected) {
          data.push(item.key)
        }
      })
    }
  })
  return data
}

export const selectedFieldsGetIDs = (field) => {
  const data = []
  field.forEach((el) => {
    if (el.selected) {
      data.push(el.key)
    }
    if (el.children.data && el.children.data.length) {
      if (el.children.data.every((item) => item.selected)) {
        el.applyFilter()
        if (!data.includes(el.key)) {
          data.push(el.key)
        }
        return data
      }
      if (el.children.data.some((item) => !item.selected)) {
        const indexLabel = data.findIndex((label) => label === el.key)
        if (indexLabel > -1) {
          data.splice(indexLabel, 1)
        }
        el.setStatus(false)
      }
      el.children.data.forEach((item) => {
        if (item.selected) {
          data.push(item.key)
        }
      })
    }
  })
  return data
}

export const resetStatus = (filterList) => {
  filterList.forEach((el) => {
    if (el.children.data) {
      el.children.data.forEach((item) => {
        if (item.selected) {
          el.toggle()
        }
      })
    }
    if (el.selected) {
      el.toggle()
    }
  })
  return filterList
}

const checkParams = (params) => {
  const checkedParams = {}
  Object.keys(params).forEach((item) => {
    const thisItem = params[item]
    checkedParams[item] = Array.isArray(thisItem)
      ? thisItem.toString()
      : thisItem
  })
  return checkedParams
}

export const generateLink = (path, params = {}, external) => {
  const checkedParams = checkParams(params)
  let paramsString = qp.toString(checkedParams)
  paramsString = paramsString ? `?${paramsString}` : ''
  const { origin, pathname } = window.location
  return `${external || origin + pathname}${paramsString}${path}`
}

export const generateDefaultLink = (url) => {
  let list = [
    { title: 'See our jobs', link: '' },
    { title: 'Refer a friend', link: '' }
  ]
  list = list.map((el) => {
    if (el.title === 'Refer a friend') {
      return { title: el.title, link: `${url}?referFriend` }
    }
    return { title: el.title, link: url }
  })
  return list
}

export const countrySort = (data) =>
  Object.entries(data)
    .map((el) => ({
      name: el[0],
      code: el[1]
    }))
    .sort((a, b) => a.code.localeCompare(b.code))

export const GIF = function () {
  let timerID // timer handle for set time out usage
  let st // holds the stream object when loading.
  const interlaceOffsets = [0, 4, 2, 1] // used in de-interlacing.
  const interlaceSteps = [8, 8, 4, 2]
  let interlacedBufSize // this holds a buffer to de interlace. Created on the first frame and when size changed
  let deinterlaceBuf
  let pixelBufSize // this holds a buffer for pixels. Created on the first frame and when size changed
  let pixelBuf
  const GIF_FILE = {
    // gif file data headers
    GCExt: 0xf9,
    COMMENT: 0xfe,
    APPExt: 0xff,
    UNKNOWN: 0x01, // not sure what this is but need to skip it in parser
    IMAGE: 0x2c,
    EOF: 59, // This is entered as decimal
    EXT: 0x21
  }
  // simple buffered stream used to read from the file
  const Stream = function (data) {
    this.data = new Uint8ClampedArray(data)
    this.pos = 0
    const len = this.data.length
    this.getString = function (count) {
      // returns a string from current pos of len count
      let s = ''
      while (count--) {
        s += String.fromCharCode(this.data[this.pos++])
      }
      return s
    }
    this.readSubBlocks = function () {
      // reads a set of blocks as a string
      let size
      let count
      let data = ''
      do {
        count = size = this.data[this.pos++]
        while (count--) {
          data += String.fromCharCode(this.data[this.pos++])
        }
      } while (size !== 0 && this.pos < len)
      return data
    }
    this.readSubBlocksB = function () {
      // reads a set of blocks as binary
      let size
      let count
      const data = []
      do {
        count = size = this.data[this.pos++]
        while (count--) {
          data.push(this.data[this.pos++])
        }
      } while (size !== 0 && this.pos < len)
      return data
    }
  }
  // LZW decoder uncompressed each frames pixels
  // this needs to be optimised.
  // minSize is the min dictionary as powers of two
  // size and data is the compressed pixels
  function lzwDecode(minSize, data) {
    let i
    let pixelPos
    let pos
    let clear
    let eod
    let size
    let done
    let dic
    let code
    let last
    let d
    let len
    pos = pixelPos = 0
    dic = []
    clear = 1 << minSize
    eod = clear + 1
    size = minSize + 1
    done = false
    while (!done) {
      // JavaScript optimisers like a clear exit though I never use 'done' apart from fooling the optimiser
      last = code
      code = 0
      for (i = 0; i < size; i++) {
        if (data[pos >> 3] & (1 << (pos & 7))) {
          code |= 1 << i
        }
        pos++
      }
      if (code === clear) {
        // clear and reset the dictionary
        dic = []
        size = minSize + 1
        for (i = 0; i < clear; i++) {
          dic[i] = [i]
        }
        dic[clear] = []
        dic[eod] = null
      } else {
        if (code === eod) {
          done = true
          return
        }
        if (code >= dic.length) {
          dic.push(dic[last].concat(dic[last][0]))
        } else if (last !== clear) {
          dic.push(dic[last].concat(dic[code][0]))
        }
        d = dic[code]
        len = d.length
        for (i = 0; i < len; i++) {
          pixelBuf[pixelPos++] = d[i]
        }
        if (dic.length === 1 << size && size < 12) {
          size++
        }
      }
    }
  }
  function parseColourTable(count) {
    // get a colour table of length count  Each entry is 3 bytes, for RGB.
    const colours = []
    for (let i = 0; i < count; i++) {
      colours.push([st.data[st.pos++], st.data[st.pos++], st.data[st.pos++]])
    }
    return colours
  }
  function parse() {
    // read the header. This is the starting point of the decode and async calls parseBlock
    let bitField
    st.pos += 6
    gif.width = st.data[st.pos++] + (st.data[st.pos++] << 8)
    gif.height = st.data[st.pos++] + (st.data[st.pos++] << 8)
    bitField = st.data[st.pos++]
    gif.colorRes = (bitField & 0b1110000) >> 4
    gif.globalColourCount = 1 << ((bitField & 0b111) + 1)
    gif.bgColourIndex = st.data[st.pos++]
    st.pos++ // ignoring pixel aspect ratio. if not 0, aspectRatio = (pixelAspectRatio + 15) / 64
    if (bitField & 0b10000000) {
      gif.globalColourTable = parseColourTable(gif.globalColourCount)
    } // global colour flag
    setTimeout(parseBlock, 0)
  }
  function parseAppExt() {
    // get application specific data. Netscape added iterations and terminator. Ignoring that
    st.pos += 1
    if (st.getString(8) === 'NETSCAPE') {
      st.pos += 8
    } // ignoring this data. iterations (word) and terminator (byte)
    else {
      st.pos += 3 // 3 bytes of string usually "2.0" when identifier is NETSCAPE
      st.readSubBlocks() // unknown app extension
    }
  }
  function parseGCExt() {
    // get GC data
    let bitField
    st.pos++
    bitField = st.data[st.pos++]
    gif.disposalMethod = (bitField & 0b11100) >> 2
    gif.transparencyGiven = !!(bitField & 0b1) // ignoring bit two that is marked as  userInput???
    gif.delayTime = st.data[st.pos++] + (st.data[st.pos++] << 8)
    gif.transparencyIndex = st.data[st.pos++]
    st.pos++
  }
  function parseImg() {
    // decodes image data to create the indexed pixel image
    let deinterlace
    let frame
    let bitField
    deinterlace = function (width) {
      // de interlace pixel data if needed
      let lines
      let fromLine
      let pass
      let toLine
      lines = pixelBufSize / width
      fromLine = 0
      if (interlacedBufSize !== pixelBufSize) {
        // create the buffer if size changed or undefined.
        deinterlaceBuf = new Uint8Array(pixelBufSize)
        interlacedBufSize = pixelBufSize
      }
      for (pass = 0; pass < 4; pass++) {
        for (
          toLine = interlaceOffsets[pass];
          toLine < lines;
          toLine += interlaceSteps[pass]
        ) {
          deinterlaceBuf.set(
            pixelBuf.subArray(fromLine, fromLine + width),
            toLine * width
          )
          fromLine += width
        }
      }
    }
    frame = {}
    gif.frames.push(frame)
    frame.disposalMethod = gif.disposalMethod
    frame.time = gif.length
    frame.delay = gif.delayTime * 10
    gif.length += frame.delay
    if (gif.transparencyGiven) {
      frame.transparencyIndex = gif.transparencyIndex
    } else {
      frame.transparencyIndex = undefined
    }
    frame.leftPos = st.data[st.pos++] + (st.data[st.pos++] << 8)
    frame.topPos = st.data[st.pos++] + (st.data[st.pos++] << 8)
    frame.width = st.data[st.pos++] + (st.data[st.pos++] << 8)
    frame.height = st.data[st.pos++] + (st.data[st.pos++] << 8)
    bitField = st.data[st.pos++]
    frame.localColourTableFlag = !!(bitField & 0b10000000)
    if (frame.localColourTableFlag) {
      frame.localColourTable = parseColourTable(1 << ((bitField & 0b111) + 1))
    }
    if (pixelBufSize !== frame.width * frame.height) {
      // create a pixel buffer if not yet created or if current frame size is different from previous
      pixelBuf = new Uint8Array(frame.width * frame.height)
      pixelBufSize = frame.width * frame.height
    }
    lzwDecode(st.data[st.pos++], st.readSubBlocksB()) // decode the pixels
    if (bitField & 0b1000000) {
      // de interlace if needed
      frame.interlaced = true
      deinterlace(frame.width)
    } else {
      frame.interlaced = false
    }
    processFrame(frame) // convert to canvas image
  }

  function processFrame(frame) {
    // creates a RGBA canvas image from the indexed pixel data.
    let ct
    let cData
    let dat
    let pixCount
    let ind
    let useT
    let i
    let pixel
    let pDat
    let col
    let ti
    frame.image = document.createElement('canvas')
    frame.image.width = gif.width
    frame.image.height = gif.height
    frame.image.ctx = frame.image.getContext('2d')
    ct = frame.localColourTableFlag
      ? frame.localColourTable
      : gif.globalColourTable
    if (gif.lastFrame === null) {
      gif.lastFrame = frame
    }
    useT = !!(
      gif.lastFrame.disposalMethod === 2 || gif.lastFrame.disposalMethod === 3
    )
    if (!useT) {
      frame.image.ctx.drawImage(
        gif.lastFrame.image,
        0,
        0,
        gif.width,
        gif.height
      )
    }
    cData = frame.image.ctx.getImageData(
      frame.leftPos,
      frame.topPos,
      frame.width,
      frame.height
    )
    ti = frame.transparencyIndex
    dat = cData.data
    if (frame.interlaced) {
      pDat = deinterlaceBuf
    } else {
      pDat = pixelBuf
    }
    pixCount = pDat.length
    ind = 0
    for (i = 0; i < pixCount; i++) {
      pixel = pDat[i]
      col = ct[pixel]
      if (ti !== pixel) {
        dat[ind++] = col[0]
        dat[ind++] = col[1]
        dat[ind++] = col[2]
        dat[ind++] = 255 // Opaque.
      } else if (useT) {
        dat[ind + 3] = 0 // Transparent.
        ind += 4
      } else {
        ind += 4
      }
    }
    frame.image.ctx.putImageData(cData, frame.leftPos, frame.topPos)
    gif.lastFrame = frame
    if (!gif.waitTillDone && typeof gif.onload === 'function') {
      doOnloadEvent()
    } // if !waitTillDone the call onload now after first frame is loaded
  }

  function finnished() {
    // called when the load has completed
    gif.loading = false
    gif.frameCount = gif.frames.length
    gif.lastFrame = null
    st = undefined
    gif.complete = true
    gif.disposalMethod = undefined
    gif.transparencyGiven = undefined
    gif.delayTime = undefined
    gif.transparencyIndex = undefined
    gif.waitTillDone = undefined
    pixelBuf = undefined // dereference pixel buffer
    deinterlaceBuf = undefined // dereference interlace buff (may or may not be used);
    pixelBufSize = undefined
    deinterlaceBuf = undefined
    gif.currentFrame = 0
    if (gif.frames.length > 0) {
      gif.image = gif.frames[0].image
    }
    doOnloadEvent()
    if (typeof gif.onloadall === 'function') {
      gif.onloadall.bind(gif)({ type: 'loadall', path: [gif] })
    }
    if (gif.playOnLoad) {
      gif.play()
    }
  }
  function canceled() {
    // called if the load has been cancelled
    finnished()
    if (typeof gif.cancelCallback === 'function') {
      gif.cancelCallback.bind(gif)({ type: 'canceled', path: [gif] })
    }
  }
  function parseExt() {
    // parse extended blocks
    const blockID = st.data[st.pos++]
    if (blockID === GIF_FILE.GCExt) {
      parseGCExt()
    } else if (blockID === GIF_FILE.COMMENT) {
      gif.comment += st.readSubBlocks()
    } else if (blockID === GIF_FILE.APPExt) {
      parseAppExt()
    } else {
      if (blockID === GIF_FILE.UNKNOWN) {
        st.pos += 13
      } // skip unknow block
      st.readSubBlocks()
    }
  }
  function parseBlock() {
    // parsing the blocks
    if (gif.cancel !== undefined && gif.cancel === true) {
      canceled()
      return
    }

    const blockId = st.data[st.pos++]
    if (blockId === GIF_FILE.IMAGE) {
      // image block
      parseImg()
      if (gif.firstFrameOnly) {
        finnished()
        return
      }
    } else if (blockId === GIF_FILE.EOF) {
      finnished()
      return
    } else {
      parseExt()
    }
    if (typeof gif.onprogress === 'function') {
      gif.onprogress({
        bytesRead: st.pos,
        totalBytes: st.data.length,
        frame: gif.frames.length
      })
    }
    setTimeout(parseBlock, 0) // parsing frame async so processes can get some time in.
  }
  function cancelLoad(callback) {
    // cancels the loading. This will cancel the load before the next frame is decoded
    if (gif.complete) {
      return false
    }
    gif.cancelCallback = callback
    gif.cancel = true
    return true
  }
  function error(type) {
    if (typeof gif.onerror === 'function') {
      gif.onerror.bind(this)({ type, path: [this] })
    }
    gif.onload = gif.onerror = undefined
    gif.loading = false
  }
  function doOnloadEvent() {
    // fire onload event if set
    gif.currentFrame = 0
    gif.nextFrameAt = gif.lastFrameAt = new Date().valueOf() // just sets the time now
    if (typeof gif.onload === 'function') {
      gif.onload.bind(gif)({ type: 'load', path: [gif] })
    }
    gif.onerror = gif.onload = undefined
  }
  function dataLoaded(data) {
    // Data loaded create stream and parse
    st = new Stream(data)
    parse()
  }
  function loadGif(filename) {
    // starts the load
    const ajax = new XMLHttpRequest()
    ajax.responseType = 'arraybuffer'
    ajax.onload = function (e) {
      if (e.target.status === 404) {
        error('File not found')
      } else if (e.target.status >= 200 && e.target.status < 300) {
        dataLoaded(ajax.response)
      } else {
        error(`Loading error : ${e.target.status}`)
      }
    }
    ajax.open('GET', filename, true)
    ajax.send()
    ajax.onerror = function () {
      error('File error')
    }
    this.src = filename
    this.loading = true
  }
  function play() {
    // starts play if paused
    if (!gif.playing) {
      gif.paused = false
      gif.playing = true
      playing()
    }
  }
  function pause() {
    // stops play
    gif.paused = true
    gif.playing = false
    clearTimeout(timerID)
  }
  function togglePlay() {
    if (gif.paused || !gif.playing) {
      gif.play()
    } else {
      gif.pause()
    }
  }
  function seekFrame(frame) {
    // seeks to frame number.
    clearTimeout(timerID)
    gif.currentFrame = frame % gif.frames.length
    if (gif.playing) {
      playing()
    } else {
      gif.image = gif.frames[gif.currentFrame].image
    }
  }
  function seek(time) {
    // time in Seconds  // seek to frame that would be displayed at time
    clearTimeout(timerID)
    if (time < 0) {
      time = 0
    }
    time *= 1000 // in ms
    time %= gif.length
    let frame = 0
    while (
      time > gif.frames[frame].time + gif.frames[frame].delay &&
      frame < gif.frames.length
    ) {
      frame += 1
    }
    gif.currentFrame = frame
    if (gif.playing) {
      playing()
    } else {
      gif.image = gif.frames[gif.currentFrame].image
    }
  }
  function playing() {
    let delay
    let frame
    if (gif.playSpeed === 0) {
      gif.pause()
    } else {
      if (gif.playSpeed < 0) {
        gif.currentFrame -= 1
        if (gif.currentFrame < 0) {
          gif.currentFrame = gif.frames.length - 1
        }
        frame = gif.currentFrame
        frame -= 1
        if (frame < 0) {
          frame = gif.frames.length - 1
        }
        delay = (-gif.frames[frame].delay * 1) / gif.playSpeed
      } else {
        gif.currentFrame += 1
        gif.currentFrame %= gif.frames.length
        delay = (gif.frames[gif.currentFrame].delay * 1) / gif.playSpeed
      }
      gif.image = gif.frames[gif.currentFrame].image
      timerID = setTimeout(playing, delay)
    }
  }
  const gif = {
    // the gif image object
    onload: null, // fire on load. Use waitTillDone = true to have load fire at end or false to fire on first frame
    onerror: null, // fires on error
    onprogress: null, // fires a load progress event
    onloadall: null, // event fires when all frames have loaded and gif is ready
    paused: false, // true if paused
    playing: false, // true if playing
    waitTillDone: true, // If true onload will fire when all frames loaded, if false, onload will fire when first frame has loaded
    loading: false, // true if still loading
    firstFrameOnly: false, // if true only load the first frame
    width: null, // width in pixels
    height: null, // height in pixels
    frames: [], // array of frames
    comment: '', // comments if found in file. Note I remember that some gifs have comments per frame if so this will be all comment concatenated
    length: 0, // gif length in ms (1/1000 second)
    currentFrame: 0, // current frame.
    frameCount: 0, // number of frames
    playSpeed: 1, // play speed 1 normal, 2 twice 0.5 half, -1 reverse etc...
    lastFrame: null, // temp hold last frame loaded so you can display the gif as it loads
    image: null, // the current image at the currentFrame
    playOnLoad: true, // if true starts playback when loaded
    // functions
    load: loadGif, // call this to load a file
    cancel: cancelLoad, // call to stop loading
    play, // call to start play
    pause, // call to pause
    seek, // call to seek to time
    seekFrame, // call to seek to frame
    togglePlay // call to toggle play and pause state
  }
  return gif
}

export const encode64 = (input) => {
  const chr = {}
  for (let i = 0; i < 256; i++) chr[i] = String.fromCharCode(i)

  let binaryGif = ''
  for (let j = 0; j < input.length; j++) binaryGif += chr[input[j]]

  let output = ''
  let i = 0
  let l = binaryGif.length
  let key = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  let chr1
  let chr2
  let chr3
  let enc1
  let enc2
  let enc3
  let enc4
  while (i < l) {
    chr1 = binaryGif.charCodeAt(i++)
    chr2 = binaryGif.charCodeAt(i++)
    chr3 = binaryGif.charCodeAt(i++)
    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63
    if (isNaN(chr2)) enc3 = enc4 = 64
    else if (isNaN(chr3)) enc4 = 64
    output =
      output +
      key.charAt(enc1) +
      key.charAt(enc2) +
      key.charAt(enc3) +
      key.charAt(enc4)
  }
  return output
}

export const stackBlurCanvasRGB = (imageData, width, height, radius) => {
  if (isNaN(radius) || radius < 1) return
  radius |= 0

  let pixels = imageData.data

  let x
  let y
  let i
  let p
  let yp
  let yi
  let yw
  let r_sum
  let g_sum
  let b_sum
  let r_out_sum
  let g_out_sum
  let b_out_sum
  let r_in_sum
  let g_in_sum
  let b_in_sum
  let pr
  let pg
  let pb
  let rbs
  let stackEnd

  let div = radius + radius + 1
  let widthMinus1 = width - 1
  let heightMinus1 = height - 1
  let radiusPlus1 = radius + 1
  let sumFactor = (radiusPlus1 * (radiusPlus1 + 1)) / 2

  let stackStart = new BlurStack()
  let stack = stackStart
  for (i = 1; i < div; i++) {
    stack = stack.next = new BlurStack()
    if (i === radiusPlus1) stackEnd = stack
  }
  stack.next = stackStart
  let stackIn = null
  let stackOut = null

  yw = yi = 0

  let mul_sum = mulTable[radius]
  let shg_sum = shgTable[radius]

  for (y = 0; y < height; y++) {
    r_in_sum = g_in_sum = b_in_sum = r_sum = g_sum = b_sum = 0

    r_out_sum = radiusPlus1 * (pr = pixels[yi])
    g_out_sum = radiusPlus1 * (pg = pixels[yi + 1])
    b_out_sum = radiusPlus1 * (pb = pixels[yi + 2])

    r_sum += sumFactor * pr
    g_sum += sumFactor * pg
    b_sum += sumFactor * pb

    stack = stackStart

    for (i = 0; i < radiusPlus1; i++) {
      stack.r = pr
      stack.g = pg
      stack.b = pb
      stack = stack.next
    }

    for (i = 1; i < radiusPlus1; i++) {
      p = yi + ((widthMinus1 < i ? widthMinus1 : i) << 2)
      r_sum += (stack.r = pr = pixels[p]) * (rbs = radiusPlus1 - i)
      g_sum += (stack.g = pg = pixels[p + 1]) * rbs
      b_sum += (stack.b = pb = pixels[p + 2]) * rbs

      r_in_sum += pr
      g_in_sum += pg
      b_in_sum += pb

      stack = stack.next
    }

    stackIn = stackStart
    stackOut = stackEnd
    for (x = 0; x < width; x++) {
      pixels[yi] = (r_sum * mul_sum) >> shg_sum
      pixels[yi + 1] = (g_sum * mul_sum) >> shg_sum
      pixels[yi + 2] = (b_sum * mul_sum) >> shg_sum

      r_sum -= r_out_sum
      g_sum -= g_out_sum
      b_sum -= b_out_sum

      r_out_sum -= stackIn.r
      g_out_sum -= stackIn.g
      b_out_sum -= stackIn.b

      p = (yw + ((p = x + radius + 1) < widthMinus1 ? p : widthMinus1)) << 2

      r_in_sum += stackIn.r = pixels[p]
      g_in_sum += stackIn.g = pixels[p + 1]
      b_in_sum += stackIn.b = pixels[p + 2]

      r_sum += r_in_sum
      g_sum += g_in_sum
      b_sum += b_in_sum

      stackIn = stackIn.next

      r_out_sum += pr = stackOut.r
      g_out_sum += pg = stackOut.g
      b_out_sum += pb = stackOut.b

      r_in_sum -= pr
      g_in_sum -= pg
      b_in_sum -= pb

      stackOut = stackOut.next

      yi += 4
    }
    yw += width
  }

  for (x = 0; x < width; x++) {
    g_in_sum = b_in_sum = r_in_sum = g_sum = b_sum = r_sum = 0

    yi = x << 2
    r_out_sum = radiusPlus1 * (pr = pixels[yi])
    g_out_sum = radiusPlus1 * (pg = pixels[yi + 1])
    b_out_sum = radiusPlus1 * (pb = pixels[yi + 2])

    r_sum += sumFactor * pr
    g_sum += sumFactor * pg
    b_sum += sumFactor * pb

    stack = stackStart

    for (i = 0; i < radiusPlus1; i++) {
      stack.r = pr
      stack.g = pg
      stack.b = pb
      stack = stack.next
    }

    yp = width

    for (i = 1; i <= radius; i++) {
      yi = (yp + x) << 2

      r_sum += (stack.r = pr = pixels[yi]) * (rbs = radiusPlus1 - i)
      g_sum += (stack.g = pg = pixels[yi + 1]) * rbs
      b_sum += (stack.b = pb = pixels[yi + 2]) * rbs

      r_in_sum += pr
      g_in_sum += pg
      b_in_sum += pb

      stack = stack.next

      if (i < heightMinus1) {
        yp += width
      }
    }

    yi = x
    stackIn = stackStart
    stackOut = stackEnd
    for (y = 0; y < height; y++) {
      p = yi << 2
      pixels[p] = (r_sum * mul_sum) >> shg_sum
      pixels[p + 1] = (g_sum * mul_sum) >> shg_sum
      pixels[p + 2] = (b_sum * mul_sum) >> shg_sum

      r_sum -= r_out_sum
      g_sum -= g_out_sum
      b_sum -= b_out_sum

      r_out_sum -= stackIn.r
      g_out_sum -= stackIn.g
      b_out_sum -= stackIn.b

      p =
        (x +
          ((p = y + radiusPlus1) < heightMinus1 ? p : heightMinus1) * width) <<
        2

      r_sum += r_in_sum += stackIn.r = pixels[p]
      g_sum += g_in_sum += stackIn.g = pixels[p + 1]
      b_sum += b_in_sum += stackIn.b = pixels[p + 2]

      stackIn = stackIn.next

      r_out_sum += pr = stackOut.r
      g_out_sum += pg = stackOut.g
      b_out_sum += pb = stackOut.b

      r_in_sum -= pr
      g_in_sum -= pg
      b_in_sum -= pb

      stackOut = stackOut.next

      yi += width
    }
  }
  return imageData
}

function BlurStack() {
  this.r = 0
  this.g = 0
  this.b = 0
  this.a = 0
  this.next = null
}

export const getContrastColor = (color: string): string =>
  contrastColor({ bgColor: color })

type GetColorContrastRatioArgs = {
  foregroundColor: string
  backgroundColor: string
}

export const getColorContrastRatio = ({
  foregroundColor,
  backgroundColor
}: GetColorContrastRatioArgs): number => hex(foregroundColor, backgroundColor)

// see https://webaim.org/resources/contrastchecker/
export const MINIMUM_CONTRAST_RATIO = 3

export const hasMinimumColorContrast = (
  contrastRatio: number,
  minimumContrastRatio: number
): boolean => contrastRatio >= minimumContrastRatio

export * from './currency'
export * from './strings'
export * from './misc'
