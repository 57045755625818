import rootStore from 'src/stores/RootStore'
import { utils } from 'src/utils/helpers'
import type { CandidateAdsListSchema } from 'src/types/APITypes'

export type ICandidateAdsListModel = {
  id: CandidateAdsListSchema['id']
  title: CandidateAdsListSchema['title']
  candidate: CandidateAdsListSchema['candidate']
  createdAt: CandidateAdsListSchema['created']
  expiredAt: CandidateAdsListSchema['expired_at']
  locationsCount: CandidateAdsListSchema['locations_count']
  skillsCount: CandidateAdsListSchema['skills_count']
  numberOfViews: CandidateAdsListSchema['number_views']
  enquiresCount: CandidateAdsListSchema['enquires_count']
  isExpired: boolean
  candidateFullName: string
}

class CandidateAdsListModel implements ICandidateAdsListModel {
  id: CandidateAdsListSchema['id']
  title: CandidateAdsListSchema['title']
  candidate: CandidateAdsListSchema['candidate']
  createdAt: CandidateAdsListSchema['created']
  expiredAt: CandidateAdsListSchema['expired_at']
  locationsCount: CandidateAdsListSchema['locations_count']
  skillsCount: CandidateAdsListSchema['skills_count']
  numberOfViews: CandidateAdsListSchema['number_views']
  enquiresCount: CandidateAdsListSchema['enquires_count']
  isExpired: ICandidateAdsListModel['isExpired']
  candidateFullName: ICandidateAdsListModel['candidateFullName']

  constructor(data: CandidateAdsListSchema) {
    this.id = data.id
    this.title = data.title
    this.candidate = data.candidate
    this.candidateFullName = `${data.candidate.first_name} ${data.candidate.last_name}`
    this.expiredAt = utils.dateFormatter(
      data.expired_at,
      '/',
      false,
      rootStore.agency.currency
    )
    this.createdAt = utils.dateFormatter(
      data.created,
      '/',
      false,
      rootStore.agency.currency
    )
    this.locationsCount = data.locations_count
    this.skillsCount = data.skills_count
    this.numberOfViews = data.number_views
    this.enquiresCount = data.enquires_count
    this.isExpired = new Date(data.expired_at) < new Date()
  }
}

export default CandidateAdsListModel
