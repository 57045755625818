/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
/* eslint-disable camelcase */
import { action, computed, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import mime from 'mime-types'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import credentials from '../constants/credentials'
import Logger from '../utils/Logger'
import socializeConfig from '../constants/socialize'
import alertMessages from '../constants/alertMessages'
import UtilsStore from './UtilsStore'
import CanvasCardStore from './CanvasCardStore'
import Field from '../models/form/Field'
import { helpers, truncate, validationErrors as errors } from '../utils/helpers'
import { fromLocalTimeToUTC } from 'src/utils/helpers/dateHelpers'

export class SocializeStore {
  availableSteps = 2
  queryKeyWord = 'publication_id'
  currentPostId = null
  initialData = {}
  testimonialInitialData = {}
  postTypes = ['job', 'profile', 'job_list']
  publications = []

  @observable companies = {
    networks: ['linkedin', 'twitter', 'facebook'],
    linkedin: {
      list: [],
      selected: [],
      publications: []
    },
    twitter: {
      list: [],
      selected: [],
      publications: []
    },
    facebook: {
      list: [],
      selected: [],
      publications: []
    }
  }
  @observable isOpenModal
  @observable sharingComplete = false
  @observable isOpenModalConfirm = false
  @observable stepIndex
  @observable.ref title
  @observable.ref description
  @observable.ref destinationURL
  @observable.ref editedTitle
  @observable.ref editedDescription
  @observable editedAdditionalTextValue
  @observable.ref tags = new Field({ name: 'tags', value: [] })
  @observable isUsedApplyText = true
  @observable isUsedreferAFriendText = true
  @observable image = new Field({
    name: 'image',
    value: null,
    file: true,
    fileData: {
      path: '',
      custom: false
    }
  })
  @observable needInvite = true
  @observable linkedInPostingPrefernce = 'non-clickable'
  @observable openSocializeCommentModal = false
  @observable publishData = {}
  @observable isSubtitleUsed = true
  @observable pending = {
    image: true,
    actions: false,
    creationPost: false,
    schedulePost: false
  }

  @observable publicationId = ''
  @observable videoCode = ''
  @observable sharedJobId
  @observable isTempShow = true
  @observable postType
  @observable mainImage
  @observable mainWideImage
  @observable additionalImage
  @observable videoThumbs = []

  @observable testimonialName
  @observable testimonialText
  @observable testimonialCompanylogo
  @observable testimonialProfileImage
  @observable testimonialVerified = false
  @observable testimonialLink
  @observable testimonialID

  @observable showAdditionalText = false
  @observable imagePending = false
  @observable testimonialsSocializePreview = {}

  sharingTypes = ['via-social-network', 'via-direct-link']
  @observable activeSharingType = this.sharingTypes[0]

  @observable directLink = ''
  @observable personalSocialNets = []
  @observable enableSharingViaCompanies = false
  @observable directTestimonialsLink = ''

  @observable enablePitchMeProfileSection = true
  @observable enablePitchMeHelpSection = true
  @observable enablePitchMeExpertiseSection = true
  @observable enablePitchMeJobSection = true
  @observable enablePitchMeClientSection = true

  @observable updateSocialiseData = false

  @observable scheduleSocialise = null
  @observable isUsedAI = false

  constructor() {
    this.isOpenModal = false
    this.stepIndex = 0
    this.title = new Field({ name: 'title', value: '' })
    this.description = new Field({ name: 'description', value: '' })
    this.destinationURL = new Field({
      name: 'destinationURL',
      value: '',
      validators: [errors.required()]
    })
    this.editedTitle = new Field({ name: 'editedTitle', value: '' })
    this.editedDescription = new Field({ name: 'editedDescription', value: '' })
  }

  @computed
  get tagString() {
    if (!this.tags.value) return []
    return this.tags.value.map((tag) => `#${tag}`).join(' ')
  }

  @computed
  get finished() {
    return this.stepIndex >= this.availableSteps - 1
  }

  @computed
  get isCustomImage() {
    return this.image.fileData.custom || false
  }

  @computed
  get additionalText() {
    if (!this.showAdditionalText) {
      return ''
    }
    const output = []

    if (this.isUsedApplyText) {
      output.push('Apply')
    }

    if (this.isUsedreferAFriendText) {
      output.push('Refer a friend')
    }

    if (output.length === 0) {
      return ''
    }

    return `Click here to ${
      output.length === 2 ? output.join(' or ') : output[0]
    }`
  }

  @computed
  get editedAdditionalText() {
    if (!this.showAdditionalText) {
      return ''
    }
    const output = []

    if (this.isUsedApplyText) {
      output.push('Apply')
    }

    if (this.isUsedreferAFriendText) {
      output.push('Refer a friend')
    }

    if (output.length === 0) {
      return ''
    }

    return `Click here to ${
      output.length === 2 ? output.join(' or ') : output[0]
    }`
  }

  @action.bound
  updateTags = (tags) => {
    this.tags.update(this.formatTags(tags))
  }

  // @action.bound
  // addScheduleSocialiseDate(date) {
  //   this.scheduleSocialise.date = date;
  // }

  // @action.bound
  // addScheduleSocialiseTime(date) {
  //   this.scheduleSocialise.date = date;
  // }

  @action.bound
  addScheduleSocialiseDateTime(dateTime) {
    this.scheduleSocialise = dateTime
  }

  @action.bound
  toggleModal() {
    this.isOpenModal = !this.isOpenModal
  }

  @action.bound
  setPreviousStep = () => {
    if (this.stepIndex > 0) {
      this.setData('stepIndex', this.stepIndex - 1)
    }
  }

  @action.bound
  setNextStep = () => {
    CanvasCardStore.setData('gifFramesCanvas', [])
    CanvasCardStore.setData('canvasImageGif', null)
    CanvasCardStore.setData('canvasImageOriginalGif', null)
    CanvasCardStore.setData('selectedGifFrame', 0)
    this.setData('stepIndex', this.stepIndex + 1)
  }

  @action.bound
  async getTemplateImage(imageBlob) {
    let response
    let filename = 'image.png'
    if (imageBlob.type && mime.extension(imageBlob.type)) {
      filename = `image.${mime.extension(imageBlob.type)}`
    }
    const formData = new FormData()
    formData.append('image', imageBlob, filename)
    try {
      response = await API.postData(
        ApiRoutes.socializeWidget.imageCreate,
        formData
      )
      this.image.update(response.data.id, {
        name: '',
        path: response.data.image,
        custom: this.image.fileData.custom
      })
      return response
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        toast.error(data.image)
      }
      throw new Error(error)
    }
  }

  @action.bound
  async getTemplateGIFImage(gifImageBlob) {
    let response
    let filename = 'image.gif'
    if (gifImageBlob.type && mime.extension(gifImageBlob.type)) {
      filename = `image.${mime.extension(gifImageBlob.type)}`
    }
    const formData = new FormData()
    formData.append('image', gifImageBlob, filename)
    try {
      response = await API.postData(
        ApiRoutes.socializeWidget.imageCreate,
        formData
      )
      return response
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        toast.error(data.image)
      }
      throw new Error(error)
    }
  }

  @action.bound
  async setTemplateImage() {
    this.setPendingState({ image: true, action: true })
    this.image.update(null, {
      name: '',
      path: '',
      custom: false
    })
    this.setPendingState({ image: false, action: false })
  }

  @action.bound
  async setInitalData(data) {
    this.initialData = data
    this.postType = data.postType
    this.title.update(truncate(data.title, socializeConfig.title.maxLength - 3))
    this.description.update(
      truncate(data.description, socializeConfig.description.maxLength - 3)
    )
    this.editedTitle.update(
      truncate(data.title, socializeConfig.title.maxLength - 3)
    )
    this.editedDescription.update(
      truncate(data.description, socializeConfig.description.maxLength - 3)
    )
    this.mainImage = data.mainImage
    this.mainWideImage = data.mainWideImage
    this.videoThumbs = data.videoThumbs
    this.additionalImage = data.additionalImage
    this.showAdditionalText = data.showAdditionalText
    this.editedAdditionalTextValue = this.editedAdditionalText
    this.tags.update(this.formatTags(data.tags))
    console.log('SocializeStore -> setInitialData', data)
    this.setPendingState({ image: false, action: false })
    await CanvasCardStore.reset()
  }

  @action.bound
  setTestimonialInitalData(data) {
    this.testimonialInitialData = data
    this.testimonialName = data.testimonialName
    this.testimonialText = data.testimonialText
    this.testimonialCompanylogo = data.testimonialCompanylogo
    this.testimonialProfileImage = data.testimonialProfileImage
    this.testimonialVerified = data.testimonialVerified
    this.testimonialLink = data.testimonialLink
    this.testimonialID = data.testimonialID
    CanvasCardStore.reset()
  }

  @action
  createTestimonialsPost = async (data) => {
    this.setPendingState({ actions: true, creationPost: true })
    let response
    try {
      if (data.canvasImage) {
        await this.getTemplateImage(data.canvasImage)
        delete data.canvasImage
        data.image_obj = this.image.value
      }
      data['used_ai'] = this.isUsedAI
      response = await API.postData(ApiRoutes.socializeWidget.postCreate, data)
      this.currentPostId = response.data.id
      this.setPendingState({ actions: false, creationPost: false })
      this.setData('directLink', response.data.publication_direct)
      this.setData(
        'personalSocialNets',
        response.data?.personal_social_nets || []
      )
      // this.fetchTestimonialsImage(response.data.id)
      return response
    } catch (error) {
      Logger.error(error)
      this.setPendingState({ actions: false, creationPost: false })
      throw new Error(error)
    }
  }

  @action
  refreshTokens = async (onComplete) => {
    try {
      const response = await API.getData(
        ApiRoutes.socializeWidget.refreshTokens
      )

      this.setData(
        'personalSocialNets',
        response.data?.personal_social_nets || []
      )

      onComplete && onComplete()
    } catch (error) {
      console.log(error)
      onComplete && onComplete('error')
    }
  }

  @action
  fetchTestimonialsImage = async (id) => {
    this.setData('imagePending', true)
    try {
      const response = await API.postData(
        ApiRoutes.socializeWidget.fetchImage(id),
        {
          id
        }
      )
      if (response.data.image_obj) {
        this.setData('imagePending', false)
        this.setData('testimonialsSocializePreview', response.data)
      } else {
        setTimeout(() => this.fetchTestimonialsImage(this.currentPostId), 1000)
      }
      return response
    } catch (error) {
      Logger.error(error)
      this.setData('imagePending', false)
      throw new Error(error)
    }
  }

  @action
  socialCallback = async ({
    name,
    socialName,
    code,
    temp_obj,
    next,
    publication,
    hasToken,
    link,
    type
  }) => {
    try {
      this.setPendingState({ actions: true })

      // auth with code
      // if (socialName === 'linkedin') {
      //   await API.postData(ApiRoutes.socializeWidget.create(socialName), {
      //     code,
      //     temp_obj
      //   })
      // } else if (socialName === 'twitter') {
      //   if (!hasToken) {
      //     await API.postData(ApiRoutes.socializeWidget.create(socialName), {
      //       code,
      //       temp_obj
      //     })
      //   }
      // }

      // publish created post
      const publicationData = await this.publishPost(
        socialName,
        publication,
        this.currentPostId,
        link
      )
      if (type === 'facebook_company') {
        publicationData.name = 'Facebook company page'
      }

      // store publucations
      this.publications.push({ ...publicationData, name: socialName })
      next && next(name)
    } catch (error) {
      next && next(name)
      Logger.error(error)
      this.setPendingState({ actions: false })
      const message = `Uh Oh, looks like there was an issue with connecting to your ${credentials[socialName].title} account. Please re-link it and try again!`
      toast.error(message)
      // toast.error(`${credentials[socialName].title} warning! ${error.message}`)
    }
  }

  @action.bound
  async fetchCompanies() {
    try {
      const response = await API.getData(
        ApiRoutes.socializeWidget.companiesList
      )
      runInAction(() => {
        this.companies.networks.forEach((network) => {
          this.companies[network].list = response.data[network].map(
            (item) => item
          )
        })
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  @action.bound
  updateCompaniesList = (network, list) => {
    this.companies[network].selected = [...list]
  }

  @action.bound
  async sendCopyId(id) {
    try {
      await API.postData(ApiRoutes.socializeWidget.handleCopy(id))
    } catch (e) {
      Logger.error(e.response)
    }
  }

  @action.bound
  async createPost(imageBlob, videoCode, gifImageBlob, isTestimonials) {
    this.setPendingState({ actions: true, creationPost: true })
    let response
    try {
      await this.getTemplateImage(imageBlob)

      const postData = {
        post_type: isTestimonials ? 'testimonial' : this.postType,
        candidate_ad:
          this.postType === 'candidate_ad'
            ? this.initialData.candidateAdId
            : null,
        job: this.postType === 'job' ? this.initialData.jobId : null,
        link:
          this.postType === 'anything'
            ? this.destinationURL.value
            : this.initialData.link,
        title: this.title.value,
        description: this.description.value,
        link_description: this.isSubtitleUsed ? this.tagString : '',
        image_obj: this.image.value,
        video_code: videoCode || ''
      }
      if (gifImageBlob) {
        const res = await this.getTemplateGIFImage(gifImageBlob)
        postData.image_gif_obj = res.data.id
      }
      postData['used_ai'] = this.isUsedAI
      response = await API.postData(
        ApiRoutes.socializeWidget.postCreate,
        postData
      )
      this.currentPostId = response.data.id
      this.setPendingState({ actions: false, creationPost: false })
      this.setData('directLink', response.data.publication_direct)
      this.setData(
        'personalSocialNets',
        response.data?.personal_social_nets || []
      )
      return response
    } catch (error) {
      Logger.error(error)
      this.setPendingState({ actions: false, creationPost: false })
      throw new Error(error)
    }
  }

  @action.bound
  async preparePublish(social_net) {
    try {
      if (social_net !== 'facebook') return Promise.resolve({})
      const response = await API.postData(
        ApiRoutes.socializeWidget.preparePublish(this.currentPostId),
        {
          social_net
        }
      )
      return response.data
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  publishPost = async (social_net, publication, postId, social_link) => {
    try {
      const postData = {
        social_net,
        publication,
        interaction_type:
          this.linkedInPostingPrefernce === 'non-clickable'
            ? 'no_click'
            : 'click'
      }
      console.log(this.linkedInPostingPrefernce)
      if (social_link) {
        postData.social_link = social_link
      }
      const response = await API.postData(
        ApiRoutes.socializeWidget.publish(postId),
        postData
      )
      /**
       * @note save data to be used in next comment section
       */
      if (social_net === 'facebook') return response.data

      const publishData = { ...this.publishData }
      publishData[social_net] = response.data
      this.setData('publishData', publishData)

      return response.data
    } catch (error) {
      Logger.error(error)
      this.setPendingState({ actions: false })
      const newError = new Error()
      newError.message = error.response.data.errors
      throw newError
    }
  }

  @action.bound
  async publishToCompanies() {
    if (!this.enableSharingViaCompanies) {
      return Promise.resolve()
    }
    this.setPendingState({ actions: true })
    try {
      let requestsData = this.companies.networks.map((network) => ({
        url: ApiRoutes.socializeWidget.publishCompanies(this.currentPostId),
        params: {
          company_ids: this.companies[network].selected,
          social_net: network,
          interaction_type:
            this.linkedInPostingPrefernce === 'non-clickable'
              ? 'no_click'
              : 'click'
        }
      }))
      requestsData = requestsData.filter(
        (request) => request.params?.company_ids?.length > 0
      )

      const publishData = {
        ...this.publishData,
        companies: this.publishData.companies ? this.publishData.companies : []
      }

      const responses = await API.postAllData(requestsData)
      let success
      let error

      console.log('responses from all requests')
      console.log(responses)

      this.companies.networks.forEach(async (network, i) => {
        if (!responses[i]) return
        const companies = responses[i].data?.companies
        success = Array.isArray(companies)
          ? companies.filter((company) => !!company.publication_id)
          : []
        // success = Object.keys(responses[i].data?.companies)
        //   .map((key) => responses[i].data[key])
        //   .filter((item) => !!item.publication_id)
        if (Array.isArray(companies) && companies.length > 0) {
          companies.forEach((company) => {
            publishData.companies.push({
              ...company,
              network: company.social_net,
              isCompany: true
            })
          })
        }

        error = Array.isArray(companies)
          ? companies.filter((company) => !!company.error)
          : []
        // error = Object.keys(responses[i].data)
        //   .map((key) => responses[i].data[key])
        //   .filter((item) => !!item.errors)

        this.companies[network].publications = success

        if (error.length > 0) {
          // await this.refreshTokens()
          this.companies.networks.forEach((network) => {
            // this.companies[network].list = []
            // this.companies[network].selected = []
            // this.companies[network].publications = []
            this.resetCompany(network)
          })
          await this.fetchCompanies()
          // const message = `Sharing via ${error.length} ${credentials[network].title} companies was failed. `
          // toast.error(
          //   `${message} ${error.map((item) => item.errors).join(', ')}`
          // )
          const message = `Booo. Sharing to your ${credentials[network].title} company page has failed. Please re-link your ${credentials[network].title} company page and try again!`
          toast.error(message)
        }
      })

      console.log('company publish data', publishData)
      this.setData('publishData', publishData)
      return responses
    } catch (error) {
      Logger.error(error, error?.response?.data)
      await this.fetchCompanies()
      this.setPendingState({ actions: false })
    }
  }

  @action.bound
  resetCompany(network) {
    this.companies[network].list = []
    this.companies[network].selected = []
    this.companies[network].publications = []
  }

  @action.bound
  async createDirectLink() {
    this.setPendingState({ actions: true })
    let response
    try {
      response = await API.postData(
        ApiRoutes.socializeWidget.directLinkCreate,
        {
          job: this.postType === 'job' ? this.initialData.jobId : null,
          link: this.initialData.link
        }
      )
      this.setData('directLink', response.data.link)
    } catch (error) {
      Logger.error(error)
    } finally {
      this.setPendingState({ actions: false })
    }
  }

  @action.bound
  async createTwitterDirectLink(isPersonal) {
    try {
      let data
      if (isPersonal) {
        data = await this.publishPost('twitter', undefined, this.currentPostId)
      } else {
        const response = await API.postData(
          ApiRoutes.socializeWidget.twitterDirectLinkCreate(this.currentPostId)
        )
        data = response.data
      }
      return data.ssr_link
    } catch (error) {
      return undefined
    }
  }

  @action.bound
  async read(id, cb) {
    try {
      const response = await API.getData(ApiRoutes.socializeWidget.read(id))
      runInAction(() => {
        this.publicationId = id
        this.videoCode = response.data.video_code || ''
        this.sharedJobId = response.data.job
      })
    } catch (error) {
      if (error.response.status === 404) {
        cb && cb()
      }
      Logger.error(error)
    }
  }

  @action.bound
  refreshTags = () => {
    this.tags.update(this.formatTags(this.initialData.tags))
  }

  @action.bound
  finishSharing = async (name, onSuccessShare) => {
    try {
      const companiesPublicationsLength = this.companies.networks.reduce(
        (acc, cur) => acc + this.companies[cur].publications.length,
        0
      )

      if (!this.publications.length && companiesPublicationsLength === 0) {
        this.setPendingState({ actions: false })
        return Promise.resolve()
      }

      // send ids of publications to init invitation
      // if (this.needInvite) {
      // let pubIds = this.publications.map((publication) => publication.id)
      // this.companies.networks.forEach((network) => {
      //   const ids = this.companies[network].publications.map(
      //     (publication) => publication.publication_id
      //   )
      //   pubIds = [...pubIds, ...ids]
      // })
      await API.postData(
        ApiRoutes.socializeWidget.sendInvitation(this.currentPostId),
        {
          need_invite_email: this.needInvite
        }
      )
      // }

      // close 'Socialize' modal
      this.setData('isOpenModal', false)
      this.setData('sharingComplete', true)

      /**
       * @note enable the comment section modal after the successful share
       */

      // if (typeof window !== 'undefined') {
      //   let url =
      //     window.location != window.parent.location
      //       ? document.referrer
      //       : document.location.href
      //   window.parent.postMessage('SUCCESS_SOCIALISATION', url)
      // }

      typeof onSuccessShare === 'function' && onSuccessShare()

      // notify user about successfull sharing
      await this.notifyUser({
        networks: this.publications.map((publication) => {
          if (publication.name) {
            return publication.name
          }
          return credentials[publication.social_net]?.title
        }),
        companies: companiesPublicationsLength,
        name
      })

      this.reset()
    } catch (error) {
      Logger.error(error)
    }
  }

  @action.bound
  scheduleSocialisePost = async (personalSocialNets, companyIDds) => {
    try {
      if (this.scheduleSocialise) {
        const utcTime = fromLocalTimeToUTC(this.scheduleSocialise)
        const dateTime = utcTime.format('YYYY-MM-DD HH:mm')
        const dateTimeForDisplay = this.scheduleSocialise.format(
          'DD-MM-YYYY [at] hh:mm A'
        )

        // this.currentPostId
        const response = await API.postData(
          ApiRoutes.socializeWidget.scheduleSocialise(this.currentPostId),
          {
            scheduled_at: dateTime,
            need_invite_email: this.needInvite,
            personal_social_nets: personalSocialNets,
            company_ids: companyIDds,
            interaction_type:
              this.linkedInPostingPrefernce === 'non-clickable'
                ? 'no_click'
                : 'click'
          }
        )

        console.log(response)

        // close 'Socialize' modal
        this.setData('isOpenModal', false)
        this.setData('sharingComplete', true)
        if (typeof window !== 'undefined') {
          let url =
            window.location != window.parent.location
              ? document.referrer
              : document.location.href
          window.parent.postMessage('SUCCESS_SOCIALISATION', url)
        }

        // notify user
        await this.notifyUser({
          isSchedule: true,
          date: dateTimeForDisplay
        })
      }
    } catch (error) {
      console.error(error)
      Logger.error(error)
    }
  }

  editScheduleSocialisePost = async ({
    updatedDateTime,
    currentPostId,
    onCallback
  }) => {
    try {
      if (updatedDateTime) {
        const utcTime = fromLocalTimeToUTC(updatedDateTime)
        const dateTime = utcTime.format('YYYY-MM-DD HH:mm')
        const response = await API.putData(
          ApiRoutes.dashboard.socialize.update(currentPostId),
          {
            scheduled_at: dateTime
          }
        )
        if (response?.status === 200) {
          onCallback && onCallback()
        }
        // notify user
        await this.notifyUser({
          isSchedule: true,
          date: updatedDateTime.format('DD-MM-YYYY hh:mm A')
        })
      }
    } catch (error) {
      /**
       * @todo handle the error case
       */
      console.log(error)
      Logger.error(error)
      onCallback && onCallback({ error: error })
    }
  }

  cancelScheduledSocialisePost = async ({ currentPostId, onCallback }) => {
    try {
      if (currentPostId) {
        const response = await API.postData(
          ApiRoutes.dashboard.socialize.cancelScheduledSocialise(currentPostId)
        )
        if (response?.status === 200) {
          onCallback && onCallback()
          // notify user
          await this.notifyUser({
            isSchedule: true,
            isCancel: true
          })
        }
      }
    } catch (error) {
      console.log(error)
      Logger.error(error)
      onCallback && onCallback({ error: error })
    }
  }

  notifyUser = async ({
    networks,
    companies,
    name,
    isSchedule,
    date,
    isCancel
  }) => {
    let timeout
    if (isSchedule) {
      if (isCancel) {
        UtilsStore.toggleModal(UtilsStore.successKey, {
          isOpen: true,
          message: `Your socialisation has been cancelled`,
          title: 'Socialisation Cancelled!'
        })
      } else {
        UtilsStore.toggleModal(UtilsStore.successKey, {
          isOpen: true,
          message: `Your socialisation has been scheduled for ${date}`,
          title: 'Socialisation Scheduled!',
          footerMessage: 'Please check Smartlinks tab for more details.'
        })
      }
      this.reset()
      return new Promise((res) => {
        timeout = setTimeout(() => {
          UtilsStore.toggleModal(UtilsStore.successKey)
          res()
          clearTimeout(timeout)
          timeout = null
        }, 40000)
      })
    } else {
      if (
        companies === 0 &&
        networks.length === 1 &&
        networks[0] === 'facebook'
      ) {
        this.reset()
        UtilsStore.toggleModal(UtilsStore.successKey, {
          isOpen: true,
          message: alertMessages.socialize(networks, companies, name),
          title: 'Socialising!',
          footerMessage: 'Please check your emails for confirmation.'
        })
        return
      }
      this.setData('openSocializeCommentModal', true)
      console.group('socialise data')
      console.log(this.companies)
      console.groupEnd()
    }
    this.reset()
  }

  @action
  setActiveSharingType = (value) => {
    this.activeSharingType = value
  }

  formatTags = (tags) =>
    tags
      .map((item) =>
        item
          .replace(/[(\s&\s)]+|[(\s-\s)]+|[(\s/\s)]+|[(\s)]+/gi, ' ')
          .replace(/[(\s)]+/gi, '_')
      )
      .map(helpers.removeSymbols)

  toggle = (key) => () => {
    this.setData(key, !this[key])
  }

  @action.bound
  reset = () => {
    this.stepIndex = 0
    this.currentPostId = null
    this.directLink = ''
    this.initialData = {}
    this.testimonialInitialData = {}
    this.tags.update([])
    this.image.update(null, {
      name: '',
      path: '',
      custom: false
    })
    this.sharingComplete = false

    this.isUsedApplyText = true
    this.isUsedreferAFriendText = true
    this.needInvite = true
    this.linkedInPostingPrefernce = 'non-clickable'
    // this.openSocializeCommentModal = false
    this.setPendingState({ image: true, actions: false })
    this.activeSharingType = this.sharingTypes[0]
    this.enableSharingViaCompanies = false
    this.companies.networks.forEach((network) => {
      this.companies[network].list = []
      this.companies[network].selected = []
      this.companies[network].publications = []
    })
    this.publications = []
    this.scheduleSocialise = null
    this.isUsedAI = false
    CanvasCardStore.reset()
  }

  @action.bound
  setPendingState(pendingState) {
    this.pending = {
      ...this.pending,
      ...pendingState
    }
  }

  @action.bound
  setData(key, data) {
    this[key] = data
  }
}

export default new SocializeStore()
