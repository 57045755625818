import { action, observable, runInAction, computed } from 'mobx'
import { toast } from 'react-toastify'
import {
  RefariDTOTypes,
  GetCandidateDetailsResponse
} from '@refari-frontend/types'

import API from 'src/utils/API'
import Logger from 'src/utils/Logger'
import { ApiRoutes } from 'src/utils/Urls'
import CandidateListModel, {
  type CandidatesListGetResponse
} from 'src/models/Candidates/ListModel'
import CandidateDetailModel from 'src/models/Candidates/DetailsModel'
import { APIResponse } from 'src/types/APITypes'
import RootStore from './RootStore'

type CandidateCreateUpdateRequestSchema = Omit<
  NonNullable<
    RefariDTOTypes['/dashboard/candidates/']['post']['requestBody']
  >['content']['application/json'],
  'id'
>

type TypeSort = {
  param: string
  direction: boolean // true means descending order
}

type IDialogType = 'edit' | 'create' | undefined

type ICandidatesMainStoreFields = {
  isLoading: boolean
  currentPage: number
  pageCount: number
  candidates: CandidateListModel[]
  candidateDetails: CandidateDetailModel | null
  sort: TypeSort
  search: string
  activeCandidateOnly: boolean
  isCandidateDetailsLoading: boolean
  dialogType: IDialogType
  isDialogOpen: boolean
  isDialogLoading: boolean
  isReferrerDetailsDialogOpen: boolean
  referrerID: string
  candidateId: number | string
  isOpenSuggestJobAlertModal: boolean
}

export type CandidatesToolboxOptions =
  | ''
  | 'is_applied'
  | 'is_registered'
  | 'is_imported'
  | 'has_job_alert'
  | 'has_job_alert_suggestion'
  | 'has_job_alert_accepted'

export type ICandidatesMainStore = ICandidatesMainStoreFields & {
  setCandidateId: (
    candidateId: ICandidatesMainStoreFields['candidateId']
  ) => void
  fetchCandidates: (onSuccess?: () => void) => void
  fetchCandidateDetails: (
    candidateID: ICandidatesMainStore['candidateId'],
    onSuccess?: () => void
  ) => Promise<void>
  sortCandidates: (sortKey: string) => void
  searchCandidates: (search: string) => void
  setPagination: (page: number) => void
  filterCandidates: (
    inputObject: {
      sort?: string
      desc?: boolean
      search?: string
      activeCandidateOnly?: boolean
      currentPage?: string
      referrerID?: string
      candidateID?: string
      toolbox?: Array<CandidatesToolboxOptions>
      referrerOwnerOnly?: boolean
    },
    onSuccess?: () => void
  ) => void
  openDialog: (dialogType: IDialogType) => void
  closeDialog: () => void
  openReferrerDetailsDialog: () => void
  closeReferrerDetailsDialog: () => void
  addNewCandidate: (form: any) => Promise<void>
  updateCandidate: (form: any) => Promise<void>
  patchCandidate: (
    candidateID: number,
    updatedData: Partial<CandidateCreateUpdateRequestSchema>
  ) => Promise<void>
  addReferrer: (form: {
    first_name: string
    last_name?: string
    email: string
    referral_date: string
  }) => Promise<void>
  reset: () => void
  openSuggestJobAlertModal: () => void
  closeSuggestJobAlertModal: () => void
  resetCandidateDetails: () => void
}

class CandidatesMainStore implements ICandidatesMainStore {
  @observable isLoading = false
  @observable currentPage = 0
  @observable pageCount = 0
  @observable candidates: ICandidatesMainStore['candidates'] = []
  @observable candidateDetails: ICandidatesMainStore['candidateDetails'] = null
  @observable sort: TypeSort = {
    param: '',
    direction: false
  }
  @observable search = ''
  @observable activeCandidateOnly = false
  @observable isCandidateDetailsLoading = false
  @observable dialogType: IDialogType
  @observable isDialogOpen = false
  @observable isDialogLoading = false
  @observable referrerID = ''
  @observable candidateId: ICandidatesMainStore['candidateId'] = 0
  @observable isReferrerDetailsDialogOpen = false
  @observable isOpenSuggestJobAlertModal = false
  @observable toolbox: CandidatesToolboxOptions = ''
  @observable referrerOwnerOnly = false

  @action
  resetCandidateDetails = () => {
    this.candidateDetails = null
  }

  @action
  openSuggestJobAlertModal = () => {
    this.isOpenSuggestJobAlertModal = true
  }

  @action
  closeSuggestJobAlertModal = () => {
    this.isOpenSuggestJobAlertModal = false
  }

  @action
  setCandidateId: ICandidatesMainStore['setCandidateId'] = (candidateId) => {
    this.candidateId = candidateId
  }

  @action
  setFetchingEnd = () => {
    this.isLoading = false
  }

  @action
  setCandidateDetailsFetchingEnd = () => {
    this.isCandidateDetailsLoading = false
  }

  @action
  setCandidateDetailsToCandidates = (
    candidateDetails: CandidateDetailModel
  ) => {
    this.candidateDetails = candidateDetails
  }

  @computed
  get getParams() {
    const params: {
      ordering?: string
      search?: string
      page?: number
      referrer?: string
      id?: number
      toolbox?: CandidatesToolboxOptions
      referrer_owner_only?: boolean
      is_active?: boolean
    } = {}
    if (this.sort.param) {
      params.ordering = this.sort.direction
        ? this.sort.param
        : `-${this.sort.param}`
    }
    if (this.search) {
      params.search = this.search
    }
    if (this.currentPage) {
      params.page = this.currentPage
    }
    if (this.referrerID) {
      params.referrer = this.referrerID
    }
    if (this.candidateId) {
      params.id = Number(this.candidateId)
    }

    if (this.toolbox) {
      params.toolbox = this.toolbox
    }

    if (this.activeCandidateOnly) {
      params.is_active = this.activeCandidateOnly
    }

    // send referrer_owner_only param only if referrerID is present
    if (this.referrerID) {
      params.referrer_owner_only = this.referrerOwnerOnly
    }

    return params
  }

  @action
  fetchCandidates = async (onSuccess?: () => void) => {
    try {
      this.isLoading = true
      // @ts-ignore define types for rootstore
      const token = await RootStore.executeCaptchaFn()
      const response: APIResponse<CandidatesListGetResponse> =
        await API.getData(
          ApiRoutes.dashboard.candidates.getCandidatesList,
          this.getParams,
          {
            captcha: token
          }
        )

      if (!response.data) {
        throw new Error("FetchCandidates API Response:: doesn't contain data")
      }
      const candidatesResponseArray = response.data.results
      runInAction(() => {
        this.candidates = candidatesResponseArray.map(
          (item) => new CandidateListModel(item)
        )
        /**
         * @TODO default 0 value setting in here is not safe
         */
        this.pageCount = response.data?.page_count || 0
        this.currentPage = response.data?.page || 0
        onSuccess && onSuccess()
      })
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.setFetchingEnd()
    }
  }

  @action
  fetchCandidateDetails = async (
    candidateID: ICandidatesMainStore['candidateId'],
    onSuccess?: () => void
  ) => {
    try {
      this.isCandidateDetailsLoading = true
      // @ts-ignore define types for rootstore
      const token = await RootStore.executeCaptchaFn()
      const response: APIResponse<GetCandidateDetailsResponse> =
        await API.getData(
          ApiRoutes.dashboard.candidates.getCandidateDetails(candidateID),
          undefined,
          {
            captcha: token
          }
        )
      if (!response.data)
        throw new Error(
          "FetchCandidatesDetails API Response:: doesn't contain data"
        )
      this.setCandidateDetailsToCandidates(
        new CandidateDetailModel(response.data)
      )
      onSuccess && onSuccess()
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.setCandidateDetailsFetchingEnd()
    }
  }

  /**
   * @todo document it
   * @param sortKey
   */
  @action
  sortCandidates = async (sortKey: string) => {
    this.currentPage = 1
    if (this.sort.param === sortKey) {
      /**
       * change the direction
       */
      this.sort = {
        param: sortKey,
        direction: !this.sort.direction
      }
    } else {
      this.sort = {
        param: sortKey,
        direction: false
      }
    }
    this.fetchCandidates()
  }

  @action
  searchCandidates = async (search: string) => {
    this.currentPage = 1
    this.search = search
    this.fetchCandidates()
  }

  @action
  setPagination = (page: number) => {
    this.currentPage = page
    this.fetchCandidates()
  }

  @action
  filterCandidates = async (
    {
      sort,
      desc,
      search,
      currentPage,
      activeCandidateOnly,
      referrerID,
      candidateID,
      toolbox,
      referrerOwnerOnly = true
    }: {
      sort?: string
      desc?: boolean
      search?: string
      activeCandidateOnly?: boolean
      currentPage?: string
      referrerID?: string
      candidateID?: string
      toolbox?: Array<CandidatesToolboxOptions>
      referrerOwnerOnly?: boolean
    },
    onSuccess?: () => void
  ) => {
    if (sort) {
      this.sort.param = sort
      if (desc) this.sort.direction = true
    }
    if (typeof activeCandidateOnly === 'boolean')
      this.activeCandidateOnly = activeCandidateOnly
    if (typeof search !== 'undefined') this.search = search
    if (typeof currentPage !== 'undefined')
      this.currentPage = +currentPage ? +currentPage : 1
    if (referrerID) this.referrerID = referrerID
    if (candidateID) this.candidateId = candidateID
    if (toolbox) this.toolbox = toolbox.join(',') as CandidatesToolboxOptions
    if (typeof referrerOwnerOnly !== 'undefined')
      this.referrerOwnerOnly = referrerOwnerOnly

    this.fetchCandidates(onSuccess)
  }

  @action
  openDialog(dialogType: IDialogType) {
    if (dialogType) {
      this.isDialogOpen = true
      this.dialogType = dialogType
    }
  }

  @action
  openReferrerDetailsDialog() {
    this.isReferrerDetailsDialogOpen = true
  }

  @action
  closeDialog() {
    this.dialogType = undefined
    this.isDialogOpen = false
  }

  @action
  closeReferrerDetailsDialog() {
    this.isReferrerDetailsDialogOpen = false
  }

  @action
  setDialogLoadingEnd = () => {
    this.isDialogLoading = false
  }

  getPayload = (form: any): CandidateCreateUpdateRequestSchema => {
    const data = {
      ...form.data
    } as CandidateCreateUpdateRequestSchema

    return {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      company_name: data.company_name,
      job_title: data.job_title,
      linkedin_profile_url: data.linkedin_profile_url
    }
  }

  @action
  addNewCandidate: ICandidatesMainStore['addNewCandidate'] = async (form) => {
    try {
      const payload = this.getPayload(form)

      this.isDialogLoading = true
      await API.postData(
        ApiRoutes.dashboard.candidates.createNewCandidate,
        payload
      )
      toast.success('New Candidate Created Successfully')
      this.closeDialog()
    } catch (error) {
      Logger.error(error as any)
      toast.error('something went wrong')
    } finally {
      this.setDialogLoadingEnd()
      this.fetchCandidates()
    }
  }

  @action
  updateCandidate: ICandidatesMainStore['updateCandidate'] = async (
    form: any
  ) => {
    try {
      this.isDialogLoading = true

      const payload = this.getPayload(form)

      await API.putData(
        ApiRoutes.dashboard.candidates.updateCandidate(this.candidateId),
        payload
      )
      toast.success('Candidate Updated Successfully')
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.setDialogLoadingEnd()
      this.fetchCandidates()
    }
  }

  @action
  patchCandidate: ICandidatesMainStore['patchCandidate'] = async (
    candidateID,
    data
  ) => {
    try {
      await API.patchData(
        ApiRoutes.dashboard.candidates.updateCandidate(candidateID),
        data
      )
      toast.success('Candidate Updated Successfully')
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.fetchCandidates()
    }
  }

  @action
  addReferrer: ICandidatesMainStore['addReferrer'] = async (form: any) => {
    try {
      await API.postData(
        ApiRoutes.dashboard.candidates.addReferrer(this.candidateId),
        form
      )
      toast.success('Candidate Referrer Added Successfully')
    } catch (error) {
      Logger.error(error as any)
    }
  }

  @action
  reset = () => {
    this.isLoading = false
    this.currentPage = 1
    this.pageCount = 1
    this.candidates = []
    this.sort = {
      param: '',
      direction: false
    }
    this.search = ''
    this.isCandidateDetailsLoading = false
    this.dialogType = undefined
    this.isDialogOpen = false
    this.isDialogLoading = false
    this.candidateId = 0
    this.candidateDetails = null
    this.referrerID = ''
    this.toolbox = ''
  }
}

export default new CandidatesMainStore()
