/* eslint-disable max-classes-per-file */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import Branding from './Branding'

export default class c {
  constructor(data) {
    if (!data) data = {}
    this.id = data.id
    this.name = data.name
    this.ownership = data.ownership_period
    this.referFromList = data.refer_from_list
    this.integration = data.integration
    this.rewardMaxDigits = data.reward_max_digits || 6
    this.rewardMax = data.reward_max || 0
    this.rewardMaxInternal = data.reward_max_internal || null

    this.rewardFee = data.reward_fee
    this.rewardUseWorktype = data.reward_use_worktype
    this.rewardFeePercent = data.reward_fee_percent || null
    this.rewardFeeAppliesToExistingJob =
      data.reward_fee_applies_to_existing_jobs

    this.rewardFeeInternal = data.reward_fee_internal
    this.rewardUseWorktypeInternal = data.reward_use_worktype_internal
    this.rewardFeePercentInternal = data.reward_fee_percent_internal || null
    this.rewardFeeAppliesToExistingJobInternal =
      data.reward_fee_applies_to_existing_jobs_internal

    this.showJobCounters = data.show_job_counters
    this.showPostedAt = data.show_posted_at
    this.showTagCloud = data.show_tag_cloud
    this.showAboutPageLinks = data.show_about_page_links
    this.showReferAFriendBlock = data.show_refer_a_friend_block
    this.showRegisterWithUsBlock = data.show_register_with_us_block
    this.showRewardOnJobList = data.show_reward_on_job_list
    this.showSalaryOnJobDetail = data.show_salary
    this.statusMatchDict = data.referral_status_match
    this.progressionStatusMatch = data.progression_status_match
    this.isInternalRecruitment = data.is_internal_recruitment
    this.hasSalaryVisibilityChanged = data.has_salary_visibility_changed
    this.jobDetailButtonText = data.job_detail_button_text || ''
    this.referButtonTitle = data.refer_job_button_text || ''
    this.referralTermsUrl = data.referral_terms_url || ''
    this.widgetBaseUrl = data.widget_base_url
    this.internalWidgetBaseUrl = data.internal_widget_base_url || ''
    this.slug = data.slug
    this.branding = new Branding(data.branding)
    this.description = (data.branding && data.branding.description) || ''
    this.showGridMode = data.show_grid_mode
    this.showConsultantOnDetail = data.show_consultant_on_detail
    this.currency = data.currency
    this.showCurrencyCode = Boolean(data?.show_currency_code)
    this.emailReferralManager = data.emails_referral_manager
      ? Array.isArray(data.emails_referral_manager)
        ? data.emails_referral_manager
        : [data.emails_referral_manager]
      : []
    this.emailJobManager = data.emails_job_manager
      ? Array.isArray(data.emails_job_manager)
        ? data.emails_job_manager
        : [data.emails_job_manager]
      : []
    this.emailFinanceManager = data.emails_finance_manager
      ? Array.isArray(data.emails_finance_manager)
        ? data.emails_finance_manager
        : [data.emails_finance_manager]
      : []
    this.emailCandidateManager = data.emails_candidate_manager
      ? Array.isArray(data.emails_candidate_manager)
        ? data.emails_candidate_manager
        : [data.emails_candidate_manager]
      : []
    this.emailApplicationManager = data.emails_application_manager
      ? Array.isArray(data.emails_application_manager)
        ? data.emails_application_manager
        : [data.emails_application_manager]
      : []
    this.emailToprecManager = data.emails_toprec_manager
      ? Array.isArray(data.emails_toprec_manager)
        ? data.emails_toprec_manager
        : [data.emails_toprec_manager]
      : []
    this.emailCandidatesEnquiries = data.emails_enquiry_manager
      ? Array.isArray(data.emails_enquiry_manager)
        ? data.emails_enquiry_manager
        : [data.emails_enquiry_manager]
      : []
    this.emailTalentAlertContact = data.emails_talent_alert_contact
      ? Array.isArray(data.emails_talent_alert_contact)
        ? data.emails_talent_alert_contact
        : [data.emails_talent_alert_contact]
      : []
    this.facebookReviewUrl = data.facebook_review_url || ''
    this.googleReviewUrl = data.google_review_url || ''
    // this.enableTestimonials = data.enable_testimonials || false
    this.enable2fa = data.enable_2fa || false
    this.force2faConsultant = data.force_2fa_consultant || false
    this.externalDetermination = data.external_determination
    this.access = data.access || ''
    this.autoExportCandidate = data.auto_export_candidate
    this.policyUrls = data.policy_urls || []
    this.allowInternalJobboard = data.allow_internal_jobboard || false
    this.autoExportReferral = data.auto_export_referral
    this.autoExportApplication = data.auto_export_application || false
    this.companyLinkedinURL = data.company_linkedin_url || ''
    this.showConsultantRating = data.show_consultant_rating
    this.showApplyWithLinkedIn = Boolean(data?.show_apply_with_linkedin)
    this.isResumeRequired = Boolean(data?.is_resume_required)
    this.allowCustomProfileUrl = data.allow_custom_profile_url || false
    this.socializeHashtags = data.socialize_hashtags || []
    this.enableToprec = data.enable_toprec || false
    this.enableTestimonials = this.enableToprec || false
    this.jobAdExtensionPeriod = data.job_ad_extension_period || 0
    this.allowRecruitersUploadSocializeImage = Boolean(
      data.allow_recruiters_upload_socialize_image
    )
    this.hasTalentBoard = data.enable_talent_board
    this.hasSubLocation = data.has_location_children
    this.talentBoardBaseUrl = data.talent_board_base_url
    this.allowAI = data.allow_ai
    this.enableProfileOnly = data.enable_profile_only || false
    this.enableGoogleReviews = data.enable_google_reviews || false
    this.showSalaryFilter = data.show_salary_filter || false
    this.ctaToprec = data.cta_toprec
    this.ctaWebsite = data.cta_website
    this.progressionExternalDetermination =
      data.progression_external_determination

    // socaial url prefixes
    this.linkedinPostUrlPrefix = data.linkedin_post_url_prefix
    this.facebookPostUrlPrefix = data.facebook_post_url_prefix
  }
}
export class Logo {
  constructor(data) {
    if (!data) data = {}
    this.image = data.image || ''
    this.thumb = data.thumb || ''
    this.thumb_large = data.thumb_large || ''
    this.id = data.id || null
  }
}
