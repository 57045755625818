/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import { observable, action, computed, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import Logger from '../utils/Logger'
import { ApiRoutes } from '../utils/Urls'
import alertMessages from '../constants/alertMessages'
import {
  getLetUsHelpYouPreviewQuestion,
  LetUsHelpYou
} from '../models/LetUsHelpYou'
import Questions from '../models/Questions'
import QuestionSetLite from '../models/Questions/QuestionSetLite'
import { mapFormServerErrors } from '../utils/helpers'
import { availabilityUnit, salaryPeriod } from '../constants/mapping'

const defaultQuestionSet = [
  {
    value: '',
    name: 'All Question Sets'
  }
]

export class MappingStore {
  @observable answersModal = false
  @observable questionsSetQuestionsModal = false
  @observable jobsModal = false
  @observable addQuestionsModal = false
  @observable addQuestionsSetModal = false
  @observable addSingleQuestionModal = false
  @observable questionData = null
  @observable candidateStatuses = []
  @observable applicationStatuses = []
  @observable fileTypes = []
  @observable questionsList = []
  @observable registrationQuestionsList = []
  @observable questionsSetList = []
  @observable allQuestionsList = []
  @observable jobsData = []
  @observable questionSetStep = 0

  @observable pagination = this.initPagination
  @observable questionPagination = this.initPagination
  @observable isFetching = true
  @observable dataFetching = true
  @observable disabledForm = false
  @observable jobsFetching = true
  @observable questionFetching = true

  @observable letUsHelpModal = false
  @observable letUsHelpInfo = {}
  @observable letUsHelpStore = {}
  @observable letUsHelpUpdate = false
  @observable letUsHelpDisable = false
  @observable letUsHelpConfirm = false
  @observable defaultQSname = null

  @observable screenerQuestionSetID = null

  @observable EntityConstructor

  @observable availableQuestionSet = [...defaultQuestionSet]

  initPagination = {
    totalCount: 0,
    pageCount: 0,
    pageCurrent: 1,
    pageSize: 0
  }

  constructor(entityConfig = {}) {
    this.EntityConstructor = entityConfig.entityConstructor
  }

  @computed
  get getFullParamsData() {
    const paramObj = {}
    paramObj.page = this.pagination.pageCurrent
    return paramObj
  }

  @action
  setLetUsHelpStore = (data) => {
    this.letUsHelpStore[data.page] = data
  }

  @action
  setLetUsHelpYouInfo = async (selectedQuestions) => {
    try {
      const questionIds = selectedQuestions.map((item) => item.id)
      const postData = { questions: questionIds }
      const fetchQuestions = await API.postData(
        ApiRoutes.dashboard.questions.questionsSetPreview(1),
        postData
      )
      const res = fetchQuestions.data
      res.results = res.results.map((el) => new LetUsHelpYou(el))
      this.setData('letUsHelpInfo', res)
      this.setData('letUsHelpModal', true)
      this.setDisabledStatus(res.results)
    } catch (e) {
      Logger(e, 'fetchLetUsHelpYouInfo')
    }
  }

  @action
  setScreenerQuestionPreview = async (questionSetID, callback) => {
    try {
      // this.letUsHelpStore = null
      this.screenerQuestionSetID = questionSetID
      const response = await API.getData(
        ApiRoutes.standalone.jobAds.questionSetsPreview(questionSetID, 1)
      )
      const res = response.data
      res.results = res.results.map((el) => new LetUsHelpYou(el))
      // const questions = response.data.results?.map((el) => new LetUsHelpYou(el))
      this.setData('letUsHelpInfo', res)
      this.setData('letUsHelpModal', true)
      this.setDisabledStatus(res.results)
      typeof callback === 'function' && callback()
    } catch (error) {
      Logger(error, 'fetchScreenerQuestionPreview')
    }
  }

  @action
  nextScreenerQuestionPreview = async (page, data, callback) => {
    try {
      this.letUsHelpStore[data.page] = data
      const response = await API.getData(
        ApiRoutes.standalone.jobAds.questionSetsPreview(
          this.screenerQuestionSetID,
          page || 1
        )
      )
      const res = response.data
      res.results = res.results.map((el) => new LetUsHelpYou(el))
      this.setData('letUsHelpInfo', res)
      this.setDisabledStatus(res.results)
      typeof callback === 'function' && callback()
    } catch (error) {
      Logger(error, 'fetchNextScreenerQuestionPreview')
    }
  }

  @action previewSingleQuestion = async (questionData) => {
    this.setData('letUsHelpInfo', {
      results: [getLetUsHelpYouPreviewQuestion(questionData)]
    })
    this.setData('letUsHelpModal', true)
  }

  @action
  nextPage = async (data, selectedQuestions) => {
    try {
      this.letUsHelpStore[data.page] = data
      if (this.letUsHelpStore[data.page + 1]) {
        this.setData('letUsHelpInfo', this.letUsHelpStore[data.page + 1])
      } else {
        const questionIds = selectedQuestions.map((item) => item.id)
        const postData = { questions: questionIds }
        const fetchQuestions = await API.postData(
          ApiRoutes.dashboard.questions.questionsSetPreview(data.page + 1),
          postData
        )
        const res = fetchQuestions.data
        res.results = res.results.map((el) => new LetUsHelpYou(el))
        this.setDisabledStatus(res.results)
        this.setData('letUsHelpInfo', res)
      }
    } catch (e) {
      Logger.error(e, 'nextPage')
    }
  }

  @action
  setDisabledStatus = (data) => {
    const status = data.map((el) => {
      if (el.isRequired) {
        if (
          (el.answerType === 'text' || el.answerType === 'integer') &&
          el.maxLength
        ) {
          return !!el.getAnswerValues.length
        }
        if (el.answerType === 'file') {
          return !!el.getAnswerValues.length
        }
        return el.getAnswerValues.some((item) => item.value)
      }
      return true
    })
    this.letUsHelpDisable = status.every((item) => item)
  }

  @action
  fetchData = async ({
    mode = 'jobads',
    dataFetch = false,
    params = {}
  } = {}) => {
    try {
      dataFetch && this.setData('dataFetching', true)
      const url =
        mode === 'jobads'
          ? ApiRoutes.dashboard.questions.list
          : ApiRoutes.dashboard.questions.questionsJobsList

      const fullParamsData = { ...this.getFullParamsData, ...params }

      const response = await API.getData(url, fullParamsData)

      if (response && response.data) {
        const questionNo =
          (response.data.page - 1) * response.data.page_size + 1
        const resultData = response.data.results.map(
          (item, index) =>
            new this.EntityConstructor(item, questionNo + index, false)
        )
        this.setData('questionsList', resultData)

        if (
          response.data.application_statuses &&
          response.data.candidate_statuses
        ) {
          const applicationStatuses = response.data.application_statuses.map(
            (status) => ({ id: status.id.toString(), name: status.name })
          )
          const candidateStatuses = response.data.candidate_statuses.map(
            (status) => ({ id: status.id.toString(), name: status.name })
          )
          this.setData('applicationStatuses', applicationStatuses)
          this.setData('candidateStatuses', candidateStatuses)
        }

        if (response.data.file_types) {
          const fileTypes = response.data.file_types.map((status) => ({
            id: status,
            name: status
          }))
          this.setData('fileTypes', fileTypes)
        }

        this.setPaginateParams({
          totalCount: response.data.count,
          pageCount: response.data.page_count,
          pageCurrent: response.data.page,
          pageSize: response.data.page_size
        })
      }
      this.setData('isFetching', false)
      this.setData('dataFetching', false)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchRegistrationData = async (dataFetch = false) => {
    try {
      dataFetch && this.setData('dataFetching', true)
      const response = await API.getData(
        ApiRoutes.dashboard.questions.registrationList,
        this.getFullParamsData
      )
      if (response && response.data) {
        const questionNo =
          (response.data.page - 1) * response.data.page_size + 1
        const resultData = response.data.results.map(
          (item, index) =>
            new this.EntityConstructor(item, questionNo + index, false)
        )
        this.setData('registrationQuestionsList', resultData)
        const applicationStatuses = response.data.application_statuses.map(
          (status) => ({ id: status.id.toString(), name: status.name })
        )
        const candidateStatuses = response.data.candidate_statuses.map(
          (status) => ({ id: status.id.toString(), name: status.name })
        )
        this.setData('applicationStatuses', applicationStatuses)
        this.setData('candidateStatuses', candidateStatuses)
        const fileTypes = response.data.file_types.map((status) => ({
          id: status,
          name: status
        }))
        this.setData('fileTypes', fileTypes)
        this.setPaginateParams({
          totalCount: response.data.count,
          pageCount: response.data.page_count,
          pageCurrent: response.data.page,
          pageSize: response.data.page_size
        })
      }
      this.setData('isFetching', false)
      this.setData('dataFetching', false)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchQuestionsSetData = async (dataFetch = false, params) => {
    try {
      dataFetch && this.setData('dataFetching', true)
      const fullParamsData = { ...this.getFullParamsData, ...params }
      const response = await API.getData(
        ApiRoutes.dashboard.questions.questionsSetList,
        fullParamsData
      )
      if (response && response.data) {
        const questionsSetList = response.data.results.map((item) => {
          item.questions = item.questions.map(
            (initem, index) => new Questions(initem, index + 1, true)
          )
          return item
        })
        this.setData('questionsSetList', questionsSetList)
        this.setPaginateParams({
          totalCount: response.data.count,
          pageCount: response.data.page_count,
          pageCurrent: response.data.page,
          pageSize: response.data.page_size
        })
        this.setData('defaultQSname', response.data.default_qs_name)
      }
      this.setData('isFetching', false)
      this.setData('dataFetching', false)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchSingleQuestionsSetData = async (id, cb) => {
    try {
      const response = await API.getData(
        ApiRoutes.dashboard.questions.questionsSetUpdate(id)
      )
      if (response && response.data) {
        const mainData = response.data
        const resultData = mainData.questions.map(
          (item, index) => new Questions(item, index + 1, true)
        )
        mainData.questions = resultData
        if (this.questionData && this.questionData.id === id) {
          this.setData('questionData', mainData)
        }
        const questionsSetList = this.questionsSetList.map((item) => {
          if (item.id === id) {
            const { constructor } = Object.getPrototypeOf(item)
            return new constructor(mainData)
          }
          return item
        })
        this.setData('questionsSetList', questionsSetList)

        cb && cb(response.data.is_default)
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchJobData = async (id, isQuestionSetTab) => {
    try {
      const jobUrl = isQuestionSetTab
        ? ApiRoutes.dashboard.questions.questionSetJobs(id)
        : ApiRoutes.dashboard.questions.jobs(id)
      const response = await API.getData(jobUrl)
      if (response && response.data) {
        this.setData('jobsData', response.data.results)
      }
      this.setData('jobsFetching', false)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchQuestions = async (
    search = '',
    seletedQuestions = [],
    questionSetID
  ) => {
    try {
      const paramObj = {}
      paramObj.page = this.questionPagination.pageCurrent
      if (typeof questionSetID === 'string')
        paramObj['question_set'] = questionSetID
      if (search) {
        paramObj.search = search
      }
      const response = await API.getData(
        ApiRoutes.dashboard.questions.questionsList,
        paramObj
      )
      if (response && response.data) {
        const questionNo =
          (response.data.page - 1) * response.data.page_size + 1
        const resultData = response.data.results.map(
          (item, index) =>
            new this.EntityConstructor(
              item,
              questionNo + index,
              !!seletedQuestions.find((question) => question.id === item.id)
            )
        )
        // const questionOrder = response.data
        // const sortedQuestionList =
        this.setData('allQuestionsList', resultData)
        runInAction(() => {
          this.questionPagination.totalCount = response.data.count
          this.questionPagination.pageCount = response.data.page_count
          this.questionPagination.pageCurrent = response.data.page
          this.questionPagination.pageSize = response.data.page_size
        })
      }
      this.setData('questionFetching', false)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  submitData = async (value, id, type, subValue = '') => {
    try {
      this.questionsList = this.questionsList.map((item) => {
        if (item.id === id) {
          if (type === 'candidate') {
            item.isCandidateUpdating = true
            item.showCandidateSubRecord = !!subValue
          } else {
            item.isApplicationUpdating = true
            item.showApplicationSubRecord = !!subValue
          }
        }
        return item
      })
      this.setData('disabledForm', true)

      const postData = { map_type: type, external_field_id: value }
      if (subValue) {
        if (value === 'availability.relative.period') {
          postData.availability_unit = subValue
        } else {
          postData.salary_period = subValue
        }
      }
      await API.putData(
        ApiRoutes.dashboard.questions.updateMapping(id),
        postData
      )
      await this.fetchData({ mode: 'jobads' })
      this.setData('disabledForm', false)
      toast.success(alertMessages.mappingUpdate)
    } catch (error) {
      this.setData('disabledForm', false)
      Logger.error(error)
    }
  }

  @action
  submitRegistrationData = async (value, id, type, subValue = '') => {
    try {
      this.registrationQuestionsList = this.registrationQuestionsList.map(
        (item) => {
          if (item.id === id) {
            if (type === 'candidate') {
              item.isCandidateUpdating = true
              item.showCandidateSubRecord = !!subValue
            } else {
              item.isApplicationUpdating = true
              item.showApplicationSubRecord = !!subValue
            }
          }
          return item
        }
      )
      this.setData('disabledForm', true)

      const postData = { map_type: type, external_field_id: value }
      if (subValue) {
        if (value === 'availability.relative.period') {
          postData.availability_unit = subValue
        } else {
          postData.salary_period = subValue
        }
      }
      await API.putData(
        ApiRoutes.dashboard.questions.registrationUpdateMapping(id),
        postData
      )
      await this.fetchRegistrationData()
      this.setData('disabledForm', false)
      toast.success(alertMessages.mappingUpdate)
    } catch (error) {
      this.setData('disabledForm', false)
      Logger.error(error)
    }
  }

  @action
  updateQuestion = async (data, mode) => {
    try {
      this.questionsList = this.questionsList.map((item) => {
        if (item.id === data.id) {
          item.isSettingUpdating = true
        }
        return item
      })
      this.setData('disabledForm', true)
      const postData = {
        prefix: data.mappingForm.fields.prefix.value,
        example: data.mappingForm.fields.example.value
      }
      if (data.mappingForm.fields.answerType.value !== 'list') {
        postData.answer_type = data.mappingForm.fields.answerType.value
      }
      await API.patchData(
        ApiRoutes.dashboard.questions.update(data.id),
        postData
      )
      await this.fetchData({ mode })
      this.setData('disabledForm', false)
      toast.success(alertMessages.questionUpdate)
    } catch (error) {
      this.setData('disabledForm', false)
      Logger.error(error)
    }
  }

  @action
  cloneSingleQuestion = async (data, mode) => {
    try {
      this.setData('disabledForm', true)
      await API.postData(ApiRoutes.dashboard.questions.clone(data.id))
      await this.fetchData({ mode })
      this.setData('disabledForm', false)
      toast.success(alertMessages.questionCloned)
    } catch (error) {
      this.setData('disabledForm', false)
      Logger.error(error)
    }
  }

  @action
  deleteSingleQuestion = async (data, mode) => {
    try {
      this.setData('disabledForm', true)
      await API.deleteData(ApiRoutes.dashboard.questions.update(data.id))
      await this.fetchData({ mode })
      this.setData('disabledForm', false)
      toast.success(alertMessages.questionRemoved)
    } catch (error) {
      this.setData('disabledForm', false)
      Logger.error(error)
    }
  }

  @action
  onSubmitQuestions = async (selectedQuestions) => {
    try {
      const questionIds = selectedQuestions.map((item) => item.id)
      this.setData('disabledForm', true)
      const postData = { questions: questionIds }
      await API.postData(
        ApiRoutes.dashboard.questions.registrationList,
        postData
      )
      await this.fetchRegistrationData()
      this.setData('disabledForm', false)
      toast.success(alertMessages.questionAdded)
      this.toggleAddQuestionsModal()
    } catch (error) {
      this.setData('disabledForm', false)
      Logger.error(error)
    }
  }

  @action
  reorderRegistrationQuestions = async (questionIds) => {
    try {
      const postData = { questions: questionIds }
      await API.postData(
        ApiRoutes.dashboard.questions.registrationList,
        postData
      )
      await this.fetchRegistrationData()
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  onSubmitQuestionsSet = async (
    selectedQuestions,
    name,
    tag,
    isDefault,
    id,
    hidePopup,
    continueEditing,
    setSecondStep
  ) => {
    try {
      const questionIds = selectedQuestions.map((question) => question.id)
      this.setData('disabledForm', true)
      const postData = {
        questions: questionIds,
        name,
        tag,
        is_default: isDefault
      }
      let response = null
      if (id) {
        response = await API.patchData(
          ApiRoutes.dashboard.questions.questionsSetUpdate(id),
          postData
        )
      } else {
        response = await API.postData(
          ApiRoutes.dashboard.questions.questionsSetList,
          postData
        )
      }
      await this.fetchQuestionsSetData()
      this.setData('disabledForm', false)
      toast.success(
        id ? alertMessages.questionSetUpdate : alertMessages.questionSetAdded
      )

      hidePopup && this.toggleAddQuestionsSetModal()

      if (response && response.data && response.data.id && continueEditing) {
        const questionData = {
          questions: selectedQuestions,
          name,
          tag,
          is_default: isDefault,
          id: response.data.id
        }
        this.setData('questionData', questionData)
      }
      this.setData('questionSetStep', setSecondStep ? 1 : 0)
    } catch (error) {
      this.setData('disabledForm', false)
      Logger.error(error)
      const { data } = error
      if (data && data.tag && data.tag.length > 0) {
        toast.error(data.tag[0])
      }
    }
  }

  @action
  setDefaultQuestionsSet = async (id) => {
    try {
      this.setData('disabledForm', true)
      const postData = { is_default: true }
      await API.patchData(
        ApiRoutes.dashboard.questions.questionsSetUpdate(id),
        postData
      )
      await this.fetchQuestionsSetData()
      this.setData('disabledForm', false)
      toast.success(alertMessages.questionSetDefault)
      this.toggleAddQuestionsSetModal()
    } catch (error) {
      this.setData('disabledForm', false)
      Logger.error(error)
    }
  }

  @action
  deleteQuestion = async (question) => {
    try {
      await API.deleteData(
        ApiRoutes.dashboard.questions.registrationUpdate(question.id)
      )
      await this.fetchRegistrationData()
      toast.success(alertMessages.questionRemoved)
    } catch (error) {
      this.setData('disabledForm', false)
      Logger.error(error)
    }
  }

  @action
  deleteQuestionsSet = async (questionSet) => {
    try {
      await API.deleteData(
        ApiRoutes.dashboard.questions.questionsSetUpdate(questionSet.id)
      )
      await this.fetchQuestionsSetData()
      toast.success(alertMessages.questionSetRemoved)
    } catch (error) {
      this.setData('disabledForm', false)
      Logger.error(error)
    }
  }

  @action
  showSubRecord = async (value, id, type) => {
    let subRecordOptions = salaryPeriod
    if (value === 'availability.relative.period') {
      subRecordOptions = availabilityUnit
    }

    this.questionsList = this.questionsList.map((item) => {
      if (item.id === id) {
        if (type === 'candidate') {
          item.showCandidateSubRecord = true
          item.candidateSubRecordOptions = subRecordOptions
          item.selectedCandidateId = value
        } else {
          item.showApplicationSubRecord = true
          item.applicationSubRecordOptions = subRecordOptions
          item.selectedApplicationId = value
        }
      }
      return item
    })
  }

  @action
  showSubRecordRegistration = async (value, id, type) => {
    let subRecordOptions = salaryPeriod
    if (value === 'availability.relative.period') {
      subRecordOptions = availabilityUnit
    }

    this.registrationQuestionsList = this.registrationQuestionsList.map(
      (item) => {
        if (item.id === id) {
          if (type === 'candidate') {
            item.showCandidateSubRecord = true
            item.candidateSubRecordOptions = subRecordOptions
            item.selectedCandidateId = value
          } else {
            item.showApplicationSubRecord = true
            item.applicationSubRecordOptions = subRecordOptions
            item.selectedApplicationId = value
          }
        }
        return item
      }
    )
  }

  @action
  onPaginate = (event, pageCurrent, mode) => {
    this.setPaginateParams({ pageCurrent })
    this.fetchData({ mode, dataFetch: true })
  }

  @action
  onRegisterPaginate = (event, pageCurrent) => {
    this.setPaginateParams({ pageCurrent })
    this.fetchRegistrationData(true)
  }

  @action
  onQuestionPaginate = (
    event,
    pageCurrent,
    selectedQuestions = [],
    questionSetID
  ) => {
    this.setData('questionFetching', true)
    if (pageCurrent) {
      this.questionPagination.pageCurrent = pageCurrent
    }
    this.fetchQuestions('', selectedQuestions, questionSetID)
  }

  @action
  onQuestionSearch = (searchName, selectedQuestions = [], questionSetID) => {
    this.setData('questionFetching', true)
    this.questionPagination = this.initPagination
    this.fetchQuestions(searchName, selectedQuestions, questionSetID)
  }

  @action
  setPaginateParams = ({ pageCurrent, pageCount, totalCount, pageSize }) => {
    if (pageCurrent) {
      this.pagination.pageCurrent = pageCurrent
    }

    if (pageCount) {
      this.pagination.pageCount = pageCount
    }

    if (totalCount) {
      this.pagination.totalCount = totalCount
    }

    if (pageSize) {
      this.pagination.pageSize = pageSize
    }
  }

  @action
  setInitPagination = () => {
    this.pagination = this.initPagination
    this.questionsList = []
    this.registrationQuestionsList = []
    this.questionsSetList = []
  }

  @computed
  get shouldShowPagination() {
    return this.pagination.totalCount > this.pagination.pageSize
  }

  @computed
  get shouldShowQuestionPagination() {
    return this.questionPagination.totalCount > this.questionPagination.pageSize
  }

  @action.bound
  toggleAnswersModal() {
    this.answersModal = !this.answersModal
  }

  @action.bound
  toggleQuestionsSetQuestionsModal() {
    this.questionsSetQuestionsModal = !this.questionsSetQuestionsModal
  }

  @action.bound
  toggleJobsModal() {
    this.jobsModal = !this.jobsModal
  }

  @action.bound
  toggleAddQuestionsModal() {
    this.addQuestionsModal = !this.addQuestionsModal
  }

  @action.bound
  toggleAddQuestionsSetModal() {
    this.addQuestionsSetModal = !this.addQuestionsSetModal
  }

  @action.bound
  toggleAddSingleQuestionModal() {
    this.addSingleQuestionModal = !this.addSingleQuestionModal
  }

  @action
  addSingleQuestion = async (form) => {
    try {
      this.setData('dataFetching', true)
      const response = await API.postData(
        ApiRoutes.dashboard.questions.questionsList,
        form.data
      )
      if (response && response.response && response.response.status === 400) {
        this.setData('dataFetching', false)
        const request = response.response.data
        mapFormServerErrors(request, form.fields)
      } else {
        await this.fetchData({ mode: 'questions' })
        this.setData('addSingleQuestionModal', false)
        toast.success(alertMessages.questionAdded)
      }
    } catch (error) {
      Logger.error(error)
      this.setData('dataFetching', false)
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  updateSingleQuestion = async (form, questionData) => {
    try {
      this.setData('dataFetching', true)
      if (
        questionData &&
        questionData.isImported &&
        form.data.answer_type === 'list'
      ) {
        delete form.data.answer_type
      }
      const response = await API.patchData(
        ApiRoutes.dashboard.questions.update(questionData.id),
        form.data
      )
      if (response && response.response && response.response.status === 400) {
        this.setData('dataFetching', false)
        const request = response.response.data
        mapFormServerErrors(request, form.fields)
      } else {
        await this.fetchData({ mode: 'questions' })
        this.setData('addSingleQuestionModal', false)
        toast.success(alertMessages.questionUpdate)
      }
    } catch (error) {
      Logger.error(error)
      this.setData('dataFetching', false)
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  fetchAvailableQuestionSet = async () => {
    try {
      const response = await API.getData(
        ApiRoutes.dashboard.questions.questionsSetListLite
      )

      if (response && response.data) {
        const normalizedResponse = response.data.results.map(
          (question) => new QuestionSetLite(question)
        )

        this.setData('availableQuestionSet', [
          ...this.availableQuestionSet,
          ...normalizedResponse
        ])
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }

  @action
  resetAvailableQuestionSet = () => {
    this.availableQuestionSet = [...defaultQuestionSet]
  }
}

export default new MappingStore()
